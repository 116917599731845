import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { pathToRegexp, compile, parse } from 'path-to-regexp';

import { withRouter } from 'react-router';
const Wrapper = styled.div`
  display: flex;

`
const LanguageButton = styled.button`
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid #999;

  &.selected {
    font-weight: 600;
    color: black;
  }
  &:last-child {
    border: none;
  }
`
const generateUrl = (locale, location) => {
  const ROUTE = '/:locale(en|es|pt)/:path*';
  const definePath = compile(ROUTE);
  const routeComponents = pathToRegexp(ROUTE).exec(location.pathname);

  let subPaths = null;
  if (routeComponents && routeComponents[2]) {
    subPaths = routeComponents[2].split('/')
  }

  return definePath({
    locale,
    path: subPaths
  })
}

class LanguageChanger extends Component {

  handleClick(lang){
    
    let url = generateUrl(lang, this.props.location);
    
    this.props.i18n.changeLanguage(lang);
    this.props.history.push(url);
  }

  render() {
    let currentLanguage = this.props.i18n.language;
    return (
      <Wrapper>
        <LanguageButton onClick={this.handleClick.bind(this, "en")} className={ currentLanguage === "en" ? "selected" : ""}>
          English
        </LanguageButton>

        <LanguageButton onClick={this.handleClick.bind(this, "es")} className={ currentLanguage === "es" ? "selected" : ""}>
          Español
        </LanguageButton>

        <LanguageButton onClick={this.handleClick.bind(this, "pt")} className={ currentLanguage === "pt" ? "selected" : ""}>
          Portuguese
        </LanguageButton>
      </Wrapper>
    )
  }
}

let mapStateToProps = state => {
  return {

  }
}

export default withTranslation()(withRouter(connect(mapStateToProps)(LanguageChanger)));