import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink, Link, withRouter } from 'react-router-dom';
import Routes from '../constants/routes';
import { Padding, Gutter, Title, MenuTitle, WhiteBtnLink } from '../stylesheets/components';
import styled from 'styled-components';
import { LanguageChanger } from './';
import { withTranslation } from 'react-i18next';
import { importImageByLocale } from '../utils';

const LeftColumnContainer = styled.div`
  min-height: 100vh;
  padding: 30px;
  background-color: white;
  border-right: 1px solid #EEE;
`;

const LeftColumnTitle = styled(Title)` 
  font-size: 2.5em;

  a {
    color: #03854B;
    text-decoration: none;
  }
`;

const LeftColumnMenuTitle = styled(MenuTitle)`
  color: #444;
  margin-bottom: 5px;
`;

const SelectedLink = styled(NavLink)`
  text-decoration: none;
  color: #666;

  &.active {
    color: #03854B;
    font-weight: 600;
  }
`;

const ProjectLink = styled.div`
  margin-left: 20px;
`;

const OrgNameArea = styled.div`
  margin-bottom: 10px;
`;

class LeftColumn extends Component {

  async handleSignout(e) {
    e.preventDefault();

    let locale = this.props.match.params.locale;
    const headers = {
      'X-CSRF-Token': this.props.form_authenticity_token,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
    // this.props.dispatch(changeProgress(true));
    const response = await fetch(`/api/sessions/sign_out.json`, { method: "delete", headers });
    if (response.ok) {
      let data = await response.json();
      document.location.href = `/${locale}`;
      // this.props.dispatch(changeProgress(false));

    } else {
      let data = await response.json();

      // this.props.dispatch(changeProgress(false));
      return data;

    }


  }

  fillNilOrg(projects) {
    let projectsWithOrgs = [];
    let projectsWithoutOrgs = [];
    _.each(projects, p => {
      if (!p.organization_name) {
        projectsWithoutOrgs.push({...p, organization_name: "No Organization"});
      } else {
        projectsWithOrgs.push({...p});
      }
    })

    return { projectsWithOrgs, projectsWithoutOrgs };
  }

  render() {
    let { user_signed_in, current_user, t } = this.props;
    let locale = this.props.match.params.locale;
    let { projectsWithOrgs, projectsWithoutOrgs } = this.fillNilOrg(current_user.projects);
    projectsWithOrgs = _.groupBy(projectsWithOrgs, p => p.organization_name);

    return (
      <LeftColumnContainer>
        <LeftColumnTitle>
          <Link to={`/${locale}`}>
            <img src={importImageByLocale(locale)} alt="Data Against Feminicide: Email Alert" />
          </Link>
        </LeftColumnTitle>
        <Gutter h={20} />
        <LeftColumnMenuTitle>
          {t('Projects')}
        </LeftColumnMenuTitle>
        {
          _.map(projectsWithOrgs, (projects, orgName) => {
            return (
              <OrgNameArea key={orgName}>
                { orgName }
                {
                  _.map(projects, project => {
                    return (
                      <ProjectLink key={project.id}>
                        <SelectedLink activeClassName='active' to={`/${locale}/projects/${project.id}`}>{project.title}</SelectedLink>
                      </ProjectLink>
                    )
                  }) 
                }                
              </OrgNameArea>
            );
          })
        }
        <OrgNameArea>
          No Organization
          {
            _.map(projectsWithoutOrgs, project => {
              return (
                <ProjectLink key={project.id}>
                  <SelectedLink activeClassName='active' to={`/${locale}/projects/${project.id}`}>{project.title}</SelectedLink>
                </ProjectLink>
              )
            }) 
          }                
        </OrgNameArea>
        <Gutter h={50} />
        <WhiteBtnLink to={`/${locale}/projects/new`}>
          {t('Create New Project')}
        </WhiteBtnLink>
        <Gutter h={20} />

        <LeftColumnMenuTitle>
          {t('Signed in as')}
        </LeftColumnMenuTitle>
        <p>
          { current_user.email }<br/>
          -<br/>
          {/* <Link to={`/${locale}/users/edit`}>{t('Edit user information')}</Link><br/> */}
          <a href="#" onClick={this.handleSignout.bind(this)}>{t('Sign out')}</a><br/>
          <Link to={`/${locale}/about`}>{t('About')}</Link>
        </p> 
        <Gutter h={10} />
        <LanguageChanger />
      </LeftColumnContainer>
    )
  }
}

let mapStateToProps = state => {
  return {
    user_signed_in: state.user_signed_in,
    current_user: state.current_user,
    form_authenticity_token: state.form_authenticity_token
  }
}

export default withTranslation()(withRouter(connect(mapStateToProps)(LeftColumn)));