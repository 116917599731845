import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: {
      "Email to Friend": "Email to\nFriend",
      "Successful Add Share": "Successfully added {{ label }} to the project.",
      "Cannot Find Project": "Cannot Found Project #{{id}}.",
      "Advanced Setting Search Terms Tester": "Advanced Setting: Search Terms Tester",
      "Terms Tester Desc": "You can optimize the search terms which are pre-configured based on selected region and language.",
      "Success Start Collection": "Successfully started collecting articles.",
      "Success Cancel Collection": "Successfully canceled collecting articles.",
      "Start Collection Date": "You started collecting articles at",
      "View": "Edit"
    }
  },
  es: {
    translation: {
      "Are you sure?": "Estas segura?",
      "Contact us": "Contacta con nosotras",
      "About": "Acerca del proyecto",
      "Count": "Cantidad",
      "Email to Friend": "Enviar correo\na una persona",
      "Search": "Búsqueda",
      "Collected Date": "Fecha de recolección",
      "From": "Desde",
      "To": "Hasta",
      "Enter Title": "Ingresar título",
      "Basic Information": "Información básica",
      "Project Title": "Título del proyecto",
      "Organization Name": "Nombre de la organización",
      "Email Delivery Frequency": "Frecuencia de entrega del correo",
      "Daily": "Diario",
      "Weekly": "Semanal",
      "Monday": "lunes",
      "Tuesday": "martes",
      "Wednesday": "miércoles",
      "Thursday": "jueves",
      "Friday": "viernes",
      "Saturday": "sábado",
      "Sunday": "domingo",
      "Language Model": "Idioma del modelo",
      "Provide Feedback on Relevance of Articles": "Proporcionar comentarios sobre la relevancia de los artículos",
      "After enabled, a feature will allow you to mark whether each news article is really a feminicide case. This contribution will be used to make the language models better for more accurately classifying feminicide news.": "Una vez habilitada la función le permitirá marcar si cada artículo de noticias es realmente un caso de feminicidio. Esta contribución se utilizará para mejorar el idioma de los modelos para clasificar con mayor precisión las noticias sobre feminicidios.",
      "You currently have the ability to provide feedback on the relevancy of articles for your project. Giving us this feedback on the dashboard page (by choosing Relevant- Yes or No on each article) helps us train the system to send you better information. You can opt out of seeing the Relevant column on your dashboard by pressing the button below.": "Actualmente tiene la capacidad de proporcionar comentarios sobre la relevancia de los artículos para su proyecto. Al darnos retroalimentación en la página del panel (eligiendo Relevante: Sí o No en cada artículo) nos ayuda a entrenar el sistema para enviarle una mejor información. Usted puede optar por no ver la columna Relevancia en su panel presionando el botón a continuación.",
      "Opt In": "Elegir",
      "Opt Out": "No participar",
      "Media Sources": "Fuentes de noticias",
      "Show Media Sources": "Mostrar fuente de noticias",
      "Email us to add new media sources to scan. (dataplusfeminism@mit.edu)": "Envíenos un correo electrónico para agregar nuevas fuentes de noticias para escanearlas",
      "Credits": "Créditos",
      "Co-Design Partners":	"Socios de co-diseño",
      "Project Leadership": "Liderazgo de proyecto",
      "Email Alert": "Alerta de correo",
      "Project Management": "Gestión de proyecto",
      "Qualitative Data Analysis": "Análisis de datos cualitativos",
      "Data Against Feminicide": "Datos contra el feminicidio",
      "Email Alerts is a tool that helps feminicide activists and civil society organizations detect cases of feminicide from news reports.": "La alerta de correo es una herramienta que ayuda a activistas feministas y organizaciones de la sociedad civil a detectar casos de feminicidio de noticias de prensa.",
      /* above is new ones */
      "Feminicide": "Feminicidio",
      "View": "Editar",
      "Sign In": "Registrarse",
      "Email": "Email",
      "Email Delivery Setting": "Configuración de entrega de correos",
      "Password": "Contraseña",
      "Create Account": "Crear Una Cuenta",
      "Forgot password?": "¿Se te olvidó tu contraseña?",
      "Sign Up": "Registrarse",
      "Full Name": "Nombre completo",
      "Password Confirmation": "Confirmación de contraseña",
      "Submit": "Enviar",
      "Request a new password": "Pide una nueva contraseña",
      "Reset Password": "Restablecer la contraseña",
      "New Password": "Nueva contraseña",
      "Confirm New Password": "Confirmar nueva contraseña",
      "Should match with the password": "Debe coincidir con la contraseña",
      "Required": "Necesaria",
      "Successfully Signed In.": "Iniciar sesión correctamente.",
      "Incorrect Password.": "Contraseña incorrecta.",
      "Projects": "Proyectos",
      "Create New Project": "Crear nuevo proyecto",
      "Signed in as": "Registrado como",
      "Edit user information": "Editar la información del usuario",
      "Sign out": "Desconectar",
      "Edit Setting": "Editar la configuración",
      "Delivering": "Entrega",
      "Once a day": "una vez al día",
      "Recent News Articles": "Artículos de noticias recientes",
      "No recent news articles.": "No hay artículos de noticias recientes.",
      "Title" : "Título",
      "Media" : "Medios de comunicación",
      "Publish Date" : "Fecha de publicación",
      "Confidence" : "Confidencia",
      "Project Language": "Idioma del proyecto",
      "Select Language...": "Seleccione el idioma...",
      "Project Region": "Región del proyecto",
      "Select Region...": "Seleccione región...",
      "Project Time Zone": "Zona horaria del proyecto",
      "Email Delivery Time": "Tiempo de entrega del correo electrónico",
      "Select...": "Seleccione...",
      "Select Delivery Time...": "Seleccionar hora de entrega...",
      "Delivery Time Setting": "Ajuste del tiempo de entrega",
      "Project Time Zone": "Zona horaria del proyecto",
      "Share with specific people": "Comparte con personas específicas",
      "Owner": "Propietaria",
      "Type Email": "Escriba su correo electrónico",
      "Add new person": "Agregar nueva persona",
      "Remove": "Eliminar",
      "Successful Add Share": "Se agregó correctamente {{ label }} al proyecto.",
      "Are you sure?": "Estas segura?",
      "Cannot Find Project": "No se puede encontrar el proyecto #{{id}}.",
      "Search Terms Tester": "Probar términos de búsqueda",
      "Terms Tester Desc": "Puede optimizar los términos de búsqueda que están preconfigurados según la región y el idioma seleccionados.",
      "Search Terms": "Términos de búsqueda",
      "Test Search Terms": "Términos de búsqueda de prueba",
      "Loading": "Cargando",
      "No Results": "No hay resultados",
      "Success Start Collection": "Comenzó a recopilar artículos con éxito.",
      "Success Cancel Collection": "Se canceló correctamente la recopilación de artículos.",
      "Start Collecting News Articles": "Comience a recopilar artículos de noticias",
      "Cancel Collecting News Articles": "Cancelar la recopilación de artículos de noticias",
      "Cancel Collection": "Cancelar colección",
      "Start Collection": "Iniciar colección",
      "Start Collection Date": "Empezaste a recopilar artículos en",
      "Limit # of Articles": "Límite de # de artículos",
      "The search terms are working properly.": "Los términos de búsqueda funcionan bien.",
      "The search terms are working properly, but returned zero news articles.": "Los términos de búsqueda funcionan bien, pero no hubo artículos de noticias relevantes.",
      // about page
      "Credits": "Créditos",
      "Co-Design Partners":	"Parceiros do processo de co-design",
      "Project Leadership": "Coordenação do projeto",
      "Email Alert": "Alerta de e-mail",
      "Development": "Desenvolvimento",
      "Design": "Design",
      "Model Development": "Desenvolvimento do modelo",
      "Data Highlighter": "Marcador de dados",
      "Development": "Desenvolvimento",
      "Design": "Design",
      "Project Management": "Gestão do projeto",
      "Qualitative Data Analysis": "Análise qualitativa de dados",
      "Data Against Feminicide": "Dados contra o feminicídio",
      "Email Alerts is a tool that helps feminicide activists and civil society organizations detect cases of feminicide from news reports.": "O alerta de e-mails é uma ferramenta que ajuda ativistas feministas e organizações da sociedade civil a detectar casos de feminicídio em notícias da imprensa.",
      "Archive": "Archivar",
      "Unarchive": "Desarchivar",
      "Archived": "Archivado",
    }
  },
  pt: {
    translation: {
      "Are you sure?": "Tem certeza?",
      "Contact us": "Contate-nos",
      "About": "Sobre o projeto",
      "Count": "Quantidade",
      "Email to Friend": "Enviar e-mail para uma pessoa",
      "Search": "Busca",
      "Collected Date": "Data de coleta",
      "From": "Desde",
      "To": "Até",
      "Enter Title": "Digitar título",
      "Basic Information": "Informação básica",
      "Project Title": "Título do projeto",
      "Organization Name": "Nome da organização",
      "Email Delivery Frequency": "Frequência de entrega do e-mail",
      "Daily": "Diário",
      "Weekly": "Semanal",
      "Monday": "Segunda-feira",
      "Tuesday": "Terça-feira",
      "Wednesday": "Quarta-feira",
      "Thursday": "Quinta-feira",
      "Friday": "Sexta-feira",
      "Saturday": "Sábado",
      "Sunday": "Domingo",
      "Language Model": "Idioma do modelo",
      "Provide Feedback on Relevance of Articles": "Deixe comentários sobre a relevância dos artigos",
      "After enabled, a feature will allow you to mark whether each news article is really a feminicide case. This contribution will be used to make the language models better for more accurately classifying feminicide news.": "Uma vez habilitado, o recurso permitirá sinalizar se cada notícia é realmente um caso de feminicídio. Essa contribuição será usada para melhorar a linguagem dos modelos para classificar com mais precisão as notícias de feminicídio.",
      "You currently have the ability to provide feedback on the relevancy of articles for your project. Giving us this feedback on the dashboard page (by choosing Relevant- Yes or No on each article) helps us train the system to send you better information. You can opt out of seeing the Relevant column on your dashboard by pressing the button below.": "Agora você pode deixar comentários sobre a relevância dos artigos para o seu projeto. Ao dar sua opinião na página do painel (escolhendo Relevante: Sim ou Não em cada artigo), você nos ajuda a treinar o sistema para que sejam enviadas informações mais relevantes para você. Você pode escolher não visualizar a coluna Relevância no seu painel clicando no botão abaixo.",
      "Opt In": "Escolher",
      "Opt Out": "Não participar",
      "Media Sources": "Fontes de notícias",
      "Show Media Sources": "Mostrar fontes de notícias",
      "Email us to add new media sources to scan. (dataplusfeminism@mit.edu)": "Envie-nos um e-mail para adicionar novas fontes de notícias para análise",
      "Credits": "Créditos",
      "Co-Design Partners":	"Parceiros do processo de co-design",
      "Project Leadership": "Coordenação do projeto",
      "Email Alert": "Alerta de e-mail",
      "Project Management": "Gestão do projeto",
      "Qualitative Data Analysis": "Análise qualitativa de dados",
      "Data Against Feminicide": "Dados contra o feminicídio",
      "Email Alerts is a tool that helps feminicide activists and civil society organizations detect cases of feminicide from news reports.": "O alerta de e-mails é uma ferramenta que ajuda ativistas feministas e organizações da sociedade civil a detectar casos de feminicídio em notícias da imprensa.",
      /* above is new ones */
      "Feminicide": "Feminicídio",
      "View": "Editar",
      "Sign In": "Entrar",
      "Email": "Email",
      "Email Delivery Setting": "Configuração de entrega de e-mails",
      "Password": "Senha",
      "Create Account": "Criar uma conta",
      "Forgot password?": "Esqueceu sua senha?",
      "Sign Up": "Registrar-se",
      "Full Name": "Nome completo",
      "Password Confirmation": "Confirmação da senha",
      "Submit": "Enviar",
      "Request a new password": "Solicitar uma nova senha",
      "Reset Password": "Redefinir senha",
      "New Password": "Nova senha",
      "Confirm New Password": "Confirmar nova senha",
      "Should match with the password": "Deve corresponder a senha inserida",
      "Required": "Necessário",
      "Successfully Signed In.": "Sessão iniciada com sucesso.",
      "Incorrect Password.": "Senha incorreta.",
      "Projects": "Projetos",
      "Create New Project": "Criar novo projeto",
      "Signed in as": "Iniciar sessão como",
      "Edit user information": "Editar as informações de usuário",
      "Sign out": "Sair",
      "Edit Setting": "Editar configurações",
      "Delivering": "Enviando",
      "Once a day": "Uma vez por dia",
      "Recent News Articles": "Notícias recentes",
      "No recent news articles.": "Nenhuma notícia recente.",
      "Title" : "Título",
      "Media" : "Meios de comunicação",
      "Publish Date" : "Data de publicação",
      "Confidence" : "Confiança",
      "Project Language": "Idioma do projeto",
      "Select Language...": "Selecionar o idioma...",
      "Project Region": "Região do projeto",
      "Select Region...": "Selecionar a região...",
      "Project Time Zone": "Fuso horário do projeto",
      "Email Delivery Time": "Horário de entrega do e-mail",
      "Select...": "Selecionar...",
      "Select Delivery Time...": "Selecionar o horário de envio...",
      "Delivery Time Setting": "Configurações do horário de envio",
      "Project Time Zone": "Fuso horário do projeto",
      "Share with specific people": "Compartilhar com pessoas específicas",
      "Owner": "Propietário",
      "Type Email": "Digite o e-mail",
      "Add new person": "Adicionar nova pessoa",
      "Remove": "Remover",
      "Successful Add Share": "Compartilhamento bem-sucedido",
      "Are you sure?": "Tem certeza?",
      "Cannot Find Project": "Não conseguimos encontrar o projeto",
      "Search Terms Tester": "Palavras-chave teste",
      "Terms Tester Desc": "Você pode otimizar os termos de busca que estão pré-configurados segundo a região e o idioma selecionados.",
      "Search Terms": "Palavras-chave de pesquisa",
      "Test Search Terms": "Palavras-chave teste",
      "Loading": "Carregando",
      "No Results": "Sem resultados",
      "Success Start Collection": "Sucesso no início da coleta de artigos",
      "Success Cancel Collection": "Sucesso no cancelamento da coleta de artigos",
      "Start Collecting News Articles": "Começar a coletar artigos de notícias",
      "Cancel Collecting News Articles": "Cancelar a coleta de artigos de notícias",
      "Cancel Collection": "Cancelar coleta",
      "Start Collection": "Iniciar coleta",
      "Start Collection Date": "Data de início da coleta",
      "Limit # of Articles": "Limitar # de artigos",
      "The search terms are working properly.": "As palavras-chave estão funcionando",
      "The search terms are working properly, but returned zero news articles.": "As palavras-chave estão funcionando, mas não foram encontrados artigos relevantes.",
      // about page
      "Credits": "Créditos",
      "Co-Design Partners":	"Parceiros do processo de co-design",
      "Project Leadership": "Coordenação do projeto",
      "Email Alert": "Alerta de e-mail",
      "Development": "Desenvolvimento",
      "Design": "Design",
      "Model Development": "Desenvolvimento do modelo",
      "Data Highlighter": "Marcador de dados",
      "Development": "Desenvolvimento",
      "Design": "Design",
      "Project Management": "Gestão do projeto",
      "Qualitative Data Analysis": "Análise qualitativa de dados",
      "Data Against Feminicide": "Dados contra o feminicídio",
      "Email Alerts is a tool that helps feminicide activists and civil society organizations detect cases of feminicide from news reports.": "O alerta de e-mails é uma ferramenta que ajuda ativistas feministas e organizações da sociedade civil a detectar casos de feminicídio em notícias da imprensa.",
      "Archive": "Arquivar",
      "Unarchive": "Desarquivar",
      "Archived": "Arquivado",
    }
  }
};

i18n
  .use(LanguageDetector) 
  .init({
    resources,
    lng: "en",
    keySeparator: false, 
    interpolation: {
      escapeValue: false 
    }
  });

  export default i18n;