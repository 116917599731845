import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { LeftColumn } from '../components';
import { DashboardContainer, RightMainColumn, FormArea, Container, FlexSpaceWrapper, Gutter, PageTitle, FormField, FormBorderWrapper, ButtonArea, BlackBtnInput, CenterFormContainer, MenuTitle } from '../stylesheets/components';
import { changeUser, changeNoticeMessage } from '../actions';
import { Form, Field } from 'react-final-form';
import Select from 'react-select';
import _ from 'lodash';
import { withTranslation, useTranslation } from 'react-i18next';

const deliveryTimeOptions = _.map(_.range(0, 24), time => {
  return { label: `${time}:00`, value: time};
})

const ReactSelectAdapter = ({ input, ...rest }) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Select placeholder={t("Select...")} {...input} {...rest} searchable />
      {(rest.meta.error || rest.meta.submitError) && rest.meta.touched && <span className="error">{rest.meta.error || rest.meta.submitError}</span>}
    </>
  );
};

class ProjectNew extends Component {
  constructor(props){
    super(props);
    this.state = {
      languages: [],
      regions: [],
      timezoneOptions: []
    }
  }

  componentDidMount(){
    this.loadData();
  }

  async loadData(){
    const headers = {
      'X-CSRF-Token': this.props.form_authenticity_token,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    const response = await fetch("/api/projects/new", { method: "get", headers });
    if (response.ok) {
      let data = await response.json();

      this.setState({
        languages: data.languages,
        regions: data.regions,
        timezoneOptions: data.timezones
      })


    } else {
      let data = await response.json();

      return data;

    }
  }


  async handleSubmit(values) {

    let locale = this.props.match.params.locale;
    const headers = {
      'X-CSRF-Token': this.props.form_authenticity_token,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    values = {
      ...values,
      project: {
        ...values.project,
        timezone: values.project.timezone.value
      }
    }
    const response = await fetch("/api/projects", { method: "post", body: JSON.stringify(values), headers });
    if (response.ok) {
      let { current_user } = this.props;
      let data = await response.json();
      
      this.props.dispatch(changeNoticeMessage("Successfully created the project."))
      this.props.dispatch(changeUser(data.current_user));
      this.props.history.push(`/${locale}/projects/${data.project.id}/edit`);
      // this.props.dispatch(changeProgress(false));

    } else {
      let data = await response.json();

      return data;

    }
  }

  handleValidate(values) {
    const errors = {};
    let { t } = this.props;
    if (values.project) {


      if (!values.project.title) {
        if (!errors.project) {
          errors.project = {};
        }
        errors.project.title = t('Required');
      }



      if (!values.project.organization_name) {
        if (!errors.project) {
          errors.project = {};
        }
        errors.project.organization_name = t('Required');
      }


      if (!values.project.region_id) {
        if (!errors.project) {
          errors.project = {};
        }
        errors.project.region_id = t('Required');
      }

      if (values.project.region_id === "-1") {
        if (!errors.project) {
          errors.project = {};
        }
        errors.project.region_id = t('Required');
      }


      if (!values.project.language_id) {
        if (!errors.project) {
          errors.project = {};
        }
        errors.project.language_id = t('Required');
      }

      if (values.project.language_id === "-1") {
        if (!errors.project) {
          errors.project = {};
        }
        errors.project.language_id = t('Required');
      }


      if (!values.project.timezone) {
        if (!errors.project) {
          errors.project = {};
        }
        errors.project.timezone = t('Required');
      }

      if (!values.project.delivery_time) {
        if (!errors.project) {
          errors.project = {};
        }
        errors.project.delivery_time = t('Required');
      }
      

      if (values.project.delivery_time === "-1") {
        if (!errors.project) {
          errors.project = {};
        }
        errors.project.delivery_time = t('Required');
      }



    }
    return errors;
  }

  render() {
    let { languages, regions, timezoneOptions } = this.state;
    let { user_signed_in, t } = this.props;
    return (
      <Fragment>
        {
          user_signed_in ? 
          <DashboardContainer>
            <LeftColumn />
            <RightMainColumn>
              <CenterFormContainer>
                <FormArea>
                  <MenuTitle>
                    {t("Create New Project")}
                  </MenuTitle>

                  <Form
                    onSubmit={this.handleSubmit.bind(this)}
                    validate={this.handleValidate.bind(this)}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                      <form method="post" onSubmit={handleSubmit}>
                        <FormBorderWrapper>
                          <FormField>
                            <label>{t("Title")}</label>
                            <Field
                              name="project[title]"
                              component="input"
                              type="text"
                              autoFocus={true}
                            >
                              {({ input, meta }) => {
                                
                                return (
                                  <div>
                                    <input {...input} placeholder="e.g., Feminicide detection" />
                                    {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || meta.submitError}</span>}
                                  </div>
                                )
                              }}
                            </Field>
                          </FormField>        
                        </FormBorderWrapper>

                        <FormBorderWrapper>
                          <FormField>
                            <label>{t("Organization Name")}</label>
                            <Field
                              name="project[organization_name]"
                              component="input"
                              type="text"
                              autoFocus={true}
                            >
                              {({ input, meta }) => {
                                
                                return (
                                  <div>
                                    <input {...input} placeholder="e.g., Data + Feminism Lab" />
                                    {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || meta.submitError}</span>}
                                  </div>
                                )
                              }}
                            </Field>
                          </FormField>        
                        </FormBorderWrapper>
                        <FormBorderWrapper>
                          <FormField>
                            <label>{t("Project Language")}</label>
                            <Field
                              name="project[language_id]"
                              component="select"
                              className="project-select"
                            > 
                              {({ input, meta }) => {
                                return (
                                  <div>
                                    <select {...input}>
                                      
                                      <option value="-1">{t("Select Language...")}</option>
                                      {
                                        _.map(languages, language => {
                                          return (
                                            <option key={language.id} value={language.id}>
                                              { language.name }
                                            </option>
                                          )
                                        })
                                      }
                                    </select>
                                    {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || meta.submitError}</span>}
                                  </div>
                                )
                              }}
                            </Field>
                          </FormField>    
                        </FormBorderWrapper>

                        <FormBorderWrapper>
                        <FormField>
                            <label>{t("Project Region")}</label>
                            <Field
                              name="project[region_id]"
                              component="select"
                              className="project-select"
                            > 
                              {({ input, meta }) => {
                                return (
                                  <div>
                                    <select {...input}>
                                      
                                      <option value="-1">{t("Select Region...")}</option>
                                      {
                                        _.map(regions, region => {
                                          return (
                                            <option key={region.id} value={region.id}>
                                              { region.name }
                                            </option>
                                          )
                                        })
                                      }
                                    </select>
                                    {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || meta.submitError}</span>}
                                  </div>
                                )
                              }}
                            </Field>
                          </FormField>    
                        </FormBorderWrapper>
                        <FormBorderWrapper>
                        <FormField>
                            <label>{t("Project Time Zone")}</label>
                            <Field
                              name="project[timezone]"
                              component={ReactSelectAdapter}
                              options={timezoneOptions}
                            >
                            </Field>
                          </FormField>    
                        </FormBorderWrapper>
                        
                        <FormBorderWrapper>
                          <FormField>
                            <label>{t("Email Delivery Time")}</label>
                            <Field
                              name="project[delivery_time]"
                              component="select"
                              className="project-select"
                            > 
                              {({ input, meta }) => {
                                return (
                                  <div>
                                    <select {...input}>
                                      
                                      <option value="-1">{t("Select Delivery Time...")}</option>
                                      {
                                        _.map(deliveryTimeOptions, option => {
                                          return (
                                            <option key={option.value} value={option.value}>
                                              { option.label }
                                            </option>
                                          )
                                        })
                                      }
                                    </select>
                                    {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || meta.submitError}</span>}
                                  </div>
                                )
                              }}
                            </Field>
                          </FormField>    
                        </FormBorderWrapper>
                        
                        <ButtonArea>
                          <div>
                            <BlackBtnInput style={{ marginRight: 10}} type="submit" value={t("Submit")} disabled={submitting || pristine} />
                          </div>
                        </ButtonArea>
                      </form>
                    )}
                  />  
                </FormArea>
              </CenterFormContainer>
            </RightMainColumn>
          </DashboardContainer> : null
        }        
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    user_signed_in: state.user_signed_in,
    current_user: state.current_user,
    form_authenticity_token: state.form_authenticity_token
  }
}

export default withTranslation()(withRouter(connect(mapStateToProps)(ProjectNew)));