import React, { Component } from 'react'
import styled from 'styled-components';
import { connect } from 'react-redux';
import { changeNoticeMessage } from '../actions';
import { FlexWrapper, FlexSpaceWrapper, MenuTitle, Gutter, GreenBtn } from '../stylesheets/components';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

const SettingArea = styled.div`

`;

const Desc = styled.div`
  max-width: 600px;
`

const EditMenuTitle = styled(MenuTitle)`
  margin-bottom: 5px;
`;

const SettingAreaSubtitle = styled.div`
  font-style: italic;
  color: #777;
`
class OptinConfirm extends Component {
  constructor(props){
    super(props);
    this.state = {
    };
  }



  async handleToggleCollection(value) {
    let { project, t } = this.props;

    const headers = {
      'X-CSRF-Token': this.props.form_authenticity_token,
      'X-Requested-With': 'XMLHttpRequest'
    }

    let formData = new FormData();    
    formData.append("project[opt_in_improving_model]", value);

    const response = await fetch(`/api/projects/${project.id}.json`, { method: "put", body: formData, headers });
    if (response.ok) {
      let data = await response.json();

      if (value) {

        this.props.dispatch(changeNoticeMessage(t("Successfully Opt-in the language model improve mode")));

      } else {

        this.props.dispatch(changeNoticeMessage(t("Successfully opt-out the language model improve mode")));
        
      }
      this.props.updateProject(data.project);

    } else {
      let data = await response.json();

      // this.props.dispatch(changeProgress(false));
      return data;

    }
  }
  

  render() {
    let { project, t } = this.props;
    return (
      <SettingArea>
        {
          project.opt_in_improving_model ? 
          <>
            <EditMenuTitle>
              {t("Provide Feedback on Relevance of Articles")}
            </EditMenuTitle>
            
            
            <Gutter h={10} />
            <Desc>
              {t("You currently have the ability to provide feedback on the relevancy of articles for your project. Giving us this feedback on the dashboard page (by choosing Relevant- Yes or No on each article) helps us train the system to send you better information. You can opt out of seeing the Relevant column on your dashboard by pressing the button below.")}
            </Desc>
            <Gutter h={10} />

            <GreenBtn data-confirm={t("Are you sure?")} onClick={this.handleToggleCollection.bind(this, false)}>
              {t("Opt Out")}
            </GreenBtn>
          </>
          :
          <>
            <EditMenuTitle>
              {t("Provide Feedback on Relevance of Articles")}
            </EditMenuTitle>
            
            <Gutter h={10} />
            <Desc>
              {t("After enabled, a feature will allow you to mark whether each news article is really a feminicide case. This contribution will be used to make the language models better for more accurately classifying feminicide news.")}
            </Desc>
            <Gutter h={10} />

            <GreenBtn data-confirm={t("Are you sure?")} onClick={this.handleToggleCollection.bind(this, true)}>
              {t("Opt In")}
            </GreenBtn>
          </>

        }
        
      </SettingArea>
    )
  }
}

let mapStateToProps = state => {
  return {
    user_signed_in: state.user_signed_in,
    current_user: state.current_user,
    form_authenticity_token: state.form_authenticity_token
  }
}
export default withTranslation()(connect(mapStateToProps)(OptinConfirm));