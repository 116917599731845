import React, { Component } from 'react'
import styled from 'styled-components';
import { connect } from 'react-redux';
import { changeNoticeMessage } from '../actions';
import { FlexWrapper, FlexSpaceWrapper, MenuTitle, Gutter, FormField } from '../stylesheets/components';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

const SettingArea = styled.div`

`;

const EditMenuTitle = styled(MenuTitle)`
  margin-bottom: 5px;
`;

const WidthWrapper = styled.div`
  width: 400px;
`

const deliveryTimeOptions = _.map(_.range(0, 24), time => {
  return { label: `${time}:00`, value: time};
})

const numberArticleOptions = [
  { label: "10", value: 10 },
  { label: "30", value: 30 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
  { label: "Deliver all", value: -1 }
]

class TimezoneSetting extends Component {
  constructor(props){
    super(props);
  }



  async handleUpdate(label, key, e) {
    let { project } = this.props;

    const headers = {
      'X-CSRF-Token': this.props.form_authenticity_token,
      'X-Requested-With': 'XMLHttpRequest'
    }

    let formData = new FormData();
    if (e.target) {
      formData.append(key, e.target.value);
    } else if (e.value) {
      formData.append(key, e.value);
    }
    
    // this.props.dispatch(changeProgress(true));
    const response = await fetch(`/api/projects/${project.id}.json`, { method: "put", body: formData, headers });
    if (response.ok) {
      let data = await response.json();

      this.props.dispatch(changeNoticeMessage(`Successfully changed ${label}.`));
      this.props.updateProject(data.project);

    } else {
      let data = await response.json();

      // this.props.dispatch(changeProgress(false));
      return data;

    }


  }
  
  render() {
    let { project, timezoneOptions, languageModels, t } = this.props;

    let selectedTimezone = _.find(timezoneOptions, tz => tz.value === project.timezone);
    // console.log(timezoneOptions, selectedTimezone);
    console.log(languageModels);
    return (
      <SettingArea>
        <EditMenuTitle>
          {t("Email Delivery Setting")}
        </EditMenuTitle>
        <WidthWrapper>
          <FormField>
            <label>{t("Project Time Zone")}</label>
            <Select 
              onChange={this.handleUpdate.bind(this, "project's time zone", "project[timezone]")}
              value={selectedTimezone}
              options={timezoneOptions} 
              searchable />
          </FormField>    
          <FormField>
            <label>{t("Email Delivery Frequency")}</label>
            <select value={project.email_frequency} onChange={this.handleUpdate.bind(this, "email delivery frequency", "project[email_frequency]")} name="project[email_frequency]">
              <option key="Daily" value="Daily">{t("Daily")}</option>
              <option key="Weekly" value="Weekly">{t("Weekly")}</option>
            </select>
          </FormField>    

          {
            project.email_frequency === "Weekly" ? 
            <FormField>
              <label>{t("Email Delivery Day")}</label>
              <select value={project.email_delivery_weekday} onChange={this.handleUpdate.bind(this, "email delivery day", "project[email_delivery_weekday]")} name="project[email_delivery_weekday]">
                <option key="Monday" value="Monday">{t('Monday')}</option>
                <option key="Tuesday" value="Tuesday">{t('Tuesday')}</option>
                <option key="Wednesday" value="Wednesday">{t('Wednesday')}</option>
                <option key="Thursday" value="Thursday">{t('Thursday')}</option>
                <option key="Friday" value="Friday">{t('Friday')}</option>
                <option key="Saturday" value="Saturday">{t('Saturday')}</option>
                <option key="Sunday" value="Sunday">{t('Sunday')}</option>
              </select>
            </FormField> : null
          }

          <FormField>
            <label>{t("Email Delivery Time")}</label>
            <select value={project.delivery_time} onChange={this.handleUpdate.bind(this, "email delivery time", "project[delivery_time]")} name="project[delivery_time]">
              {
                _.map(deliveryTimeOptions, option => {
                  return (
                    <option key={option.value} value={option.value} >
                      { option.label }
                    </option>
                  )
                })
              }
            </select>
          </FormField>    
          <FormField>
            <label>{t("Limit # of Articles")}</label>
            <select value={project.max_articles_email} onChange={this.handleUpdate.bind(this, "the limit of articles in the email", "project[max_articles_email]")} name="project[max_articles_email]">
              {
                _.map(numberArticleOptions, option => {
                  return (
                    <option key={option.value} value={option.value} >
                      { option.label }
                    </option>
                  )
                })
              }
            </select>
          </FormField>    
          <FormField>
            <label>{t("Language Model")}</label>
            <select value={project.language_model_id} onChange={this.handleUpdate.bind(this, "language model", "project[language_model_id]")} name="project[language_model_id]">
              {
                _.map(languageModels, option => {
                  return (
                    <option key={option.id} value={option.id} >
                      { option.name } 
                      {
                        !_.isNull(option.version) ? 
                        ` v${option.version}` : ""
                      }
                    </option>
                  )
                })
              }
            </select>
          </FormField>    
        </WidthWrapper>

      </SettingArea>
    )
  }
}

let mapStateToProps = state => {
  return {
    user_signed_in: state.user_signed_in,
    current_user: state.current_user,
    form_authenticity_token: state.form_authenticity_token
  }
}
export default withTranslation()(connect(mapStateToProps)(TimezoneSetting));