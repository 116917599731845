import React, { Component } from 'react'
import styled from 'styled-components';
import { connect } from 'react-redux';
import { BlackBtnInput, MenuTitle, Gutter, FormField,
  ArticleArea,
  ArticleHeader,
  ArticleList,
  ArticleInfo,
  DashboardMenuTitle } from '../stylesheets/components';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import TextareaAutosize from 'react-autosize-textarea';

const SettingArea = styled.div`

`;

const EditMenuTitle = styled(MenuTitle)`
  margin-bottom: 5px;
`;

const SettingAreaSubtitle = styled.div`
  font-style: italic;
  color: #777;
`

const ErrorArea = styled.div`
  color: red;
`;

class SearchTermsTester extends Component {
  constructor(props){
    super(props);
    this.state = {
      testStringStatus: "init",
      testResults: [],
      testError: null
    };
  }
  handleSearchTermChange(e){
    this.props.updateProject({
      ...this.props.project,
      search_terms: e.target.value  
    });

  }


  async handleUpdate(e) {
    let { project} = this.props;
    const headers = {
      'X-CSRF-Token': this.props.form_authenticity_token,
      'X-Requested-With': 'XMLHttpRequest'
    }

    let value = this.props.project.search_terms;
    let formData = new FormData();    
    formData.append("project[search_term]", value);

    // this.props.dispatch(changeProgress(true));
    const response = await fetch(`/api/projects/${project.id}.json`, { method: "put", body: formData, headers });
    if (response.ok) {
      let data = await response.json();

      this.props.updateProject(data.project);
  
    } else {
      let data = await response.json();

      // this.props.dispatch(changeProgress(false));
      return data;

    }


  }

  async handleTest(e){
    const headers = {
      'X-CSRF-Token': this.props.form_authenticity_token,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    let value = this.props.project.search_terms;
    this.setState({
      testResults: [],
      testStringStatus: "loading"
    });

    
    const response = await fetch(`/api/projects/${this.props.project.id}/test_query.json`, { method: "post", body: JSON.stringify({ query: value }), headers });
    if (response.ok) {
      let data = await response.json();
      this.setState({
        testResults: data.results,
        testStringStatus: "loaded"
      })
    } else {
      let data = await response.json();

      this.setState({
        testError: data.error.error,
        testStringStatus: "error"
      })
      
    }

  }

  render() {
    let { project, t } = this.props;
    return (
      <SettingArea>
        <EditMenuTitle>
          {t("Search Terms Tester")}
        </EditMenuTitle>
        <SettingAreaSubtitle>
          {t("Terms Tester Desc")}
        </SettingAreaSubtitle>

        <Gutter h={10} />
        <FormField>
          <label>{t("Search Terms")}</label>
    
          <div>
            <TextareaAutosize placeholder="e.g., Feminicides OR Homicide" name="project[search_terms]" value={project.search_terms} onBlur={this.handleUpdate.bind(this)} onChange={this.handleSearchTermChange.bind(this)}></TextareaAutosize>
          </div>    
        </FormField>   
        <BlackBtnInput onClick={this.handleTest.bind(this)} type="submit" disabled={this.state.testStringStatus === "loading"} value={t("Test Search Terms")} />
        <Gutter h={10} />

        {
          this.state.testStringStatus === "loading" ? 
          <div>
            {t("Loading")}...
          </div> : (
            this.state.testStringStatus === "loaded" ? 
            <div>
              {t("The search terms are working properly.")}
            </div>: null
          )                      
        }
        {
          this.state.testStringStatus === "error" ?
          <ErrorArea>
            { this.state.testError }
          </ErrorArea> : null
        }
      </SettingArea>
    )
  }
}

let mapStateToProps = state => {
  return {
    user_signed_in: state.user_signed_in,
    current_user: state.current_user,
    form_authenticity_token: state.form_authenticity_token
  }
}
export default withTranslation()(connect(mapStateToProps)(SearchTermsTester));

/*
              <ArticleArea>
                <ArticleHeader>
                  <ArticleInfo className="head title">
                    {t("Title")}
                  </ArticleInfo>
                  <ArticleInfo className="head media">
                    {t("Media")}
                  </ArticleInfo>
                  <ArticleInfo className="head date">
                    {t("Publish Date")}
                  </ArticleInfo>
                </ArticleHeader>
                <div>
                  {
                    _.map(this.state.testResults, article => {
                      return (
                        <ArticleList key={article.stories_id} href={article.url} target="_blank">
                          <ArticleInfo className="title">
                            <a href={article.url} target="_blank">{ article.title }</a>
                          </ArticleInfo>
                          <ArticleInfo className="title media">
                            { article.media_name }
                          </ArticleInfo>
                          <ArticleInfo className="title date">
                            { moment(article.publish_date).format("MMM DD, YYYY") }
                          </ArticleInfo>
                        </ArticleList>
                        
                      )
                    })
                  }
                </div>

              </ArticleArea> : 
              */