import _ from 'lodash';

import logo_en from "../assets/logo_en.svg";
import logo_es from "../assets/logo_es.svg";
import logo_pt from "../assets/logo_pt.svg";

export const numberWithDelimiter = (number, delimiter, separator) => {
  try {
    var delimiter = delimiter || ",";
    var separator = separator || ".";
    
    var parts = number.toString().split('.');
    parts[0] = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1" + delimiter);
    return parts.join(separator);
  } catch(e) {
    return number
  }
};

export const tablize = (str) => {
  return _.snakeCase(str) + "s";
}

export const importImageByLocale = (locale) => {
  switch(locale){
    case 'en':
      return logo_en;
    case 'es':
      return logo_es;
    case 'pt':
      return logo_pt;
    default:
      return logo_en;
  }
}