import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux';
import { changeNoticeMessage } from '../actions';
import { withRouter, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { 
  DashboardMenuTitle, GreenBtn } from '../stylesheets/components';


const Area = styled.div`
  display: flex;
  align-items: center;
` 
const Title = styled.div`
  margin-right: 10px;

`
const WGutter = styled.div`
  width: ${props => props.w}px;
`

class FeminicideCheckDropdown extends Component {
  constructor(props){
    super(props);
    this.state = {
      isRelevant: this.props.is_relevant
    }
  }
  handleSelectUpdate(e){
    this.setState({
      isRelevant: (
        e.target.value === "N/A" ? null : 
        (e.target.value === "yes")
      )
    }, ()=> {
      this.handleUpdate();
    })
  }

  async handleUpdate(){
    let { isRelevant } = this.state;
    let { t } = this.props;
    const headers = {
      'X-CSRF-Token': this.props.form_authenticity_token,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    // if (this.state.isRelevant === "none") {
    //   alert("You need to choose yes or no");
    //   return false;
    // }

    let finalVariables = {};

    const response = await fetch(`/api/projects/${this.props.project_id}/article_events/bulk_update.json`, { 
      method: "post", 
      body: JSON.stringify({ 
        is_relevant: isRelevant,
        article_event_ids: [this.props.id],
      }), headers });
    if (response.ok) {
      let data = await response.json();
     
      this.props.dispatch(changeNoticeMessage(t("Successfully updated.")));

    } else {
      let data = await response.json();

    }

  }


  render() {
    let { isRelevant } = this.state;
    let { t } = this.props;

    let relevantValueStr = (
      _.isNull(isRelevant) ? "N/A" : 
      (isRelevant ? "yes" : "no")
    );
    // console.log(this.props.id, this.props.title, relevantValueStr);
    return (
      <Area>
        <select value={relevantValueStr} onChange={this.handleSelectUpdate.bind(this)} name="isRelevant">
          <option value="N/A">
            N/A
          </option>
          <option value="yes">
            Yes
          </option>
          <option value="no">
            No
          </option>   
        </select>
      </Area>
    )
  }
}

let mapStateToProps = state => {
  return {
    form_authenticity_token: state.form_authenticity_token
  }
}

export default withTranslation()(withRouter(connect(mapStateToProps)(FeminicideCheckDropdown)));