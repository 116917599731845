import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Container, FlexSpaceWrapper, Gutter, CenterFormContainer, FormArea, MenuTitle, PageTitle, FormField, FormBorderWrapper, ButtonArea, BlackBtnInput, WhiteBtnA } from '../stylesheets/components';
import Routes from '../constants/routes';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { changeNoticeMessage } from '../actions';
import { Form, Field } from 'react-final-form';
import qs from 'qs';
import {LanguageChanger } from '../components';
import { withTranslation } from 'react-i18next';
import logo_en from "../assets/logo_en.svg";
import logo_es from "../assets/logo_es.svg";

const Fragment = React.Fragment;

const SigninFlexWrapper = styled.div`
  width: 100%;
`;

class PasswordEdit extends Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }
  componentDidMount(){
    // this.loadData();
  }


  
  async handleSubmit(values) {
    let locale = this.props.match.params.locale;
    const headers = {
      'X-CSRF-Token': this.props.form_authenticity_token,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
    console.log(values);
    const response = await fetch(Routes.api_user_password_update_path(), { method: "put", body: JSON.stringify(values), headers });
    if (response.ok) {
      let data = await response.json();
      console.log(data);

      this.props.dispatch(changeNoticeMessage("Password is successfully changed. Please sign in here!"));
      this.props.history.push(`/${locale}/users/sign_in`);
      // this.props.dispatch(changeProgress(false));

    } else {
      let data = await response.json();
      // this.props.dispatch(changeProgress(false));

      return data;

    }
  }




  handleValidate(values) {
    const errors = {};
    let { t } = this.props;
    if (values.user) {


      if (values.user.password !== values.user.password_confirmation) {
        if (!errors.user) {
          errors.user = {};
        }
        errors.user.password_confirmation = t('Should match with the password');
      }



    }
    return errors;
  }

  render() {
    let { windowWidth, form_authenticity_token, t } = this.props;

    let params = qs.parse(this.props.location.search.replace("?", ""));
    let locale = this.props.match.params.locale;
    // console.log(params);
    return (
      <Fragment>
        <CenterFormContainer>
          <SigninFlexWrapper>
            <PageTitle>
              <img src={locale === "en" ? logo_en : logo_es } alt="Data Against Feminicide: Email Alert" />
            </PageTitle>

            <FormArea>
              <MenuTitle>
                {t('Reset Password')}
              </MenuTitle>
              <Form
                onSubmit={this.handleSubmit.bind(this)}
                validate={this.handleValidate.bind(this)}
                initialValues={{
                  user: {
                    reset_password_token: params.reset_password_token 
                  }
                }}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                  <form method="post" onSubmit={handleSubmit}>
                  <input type="hidden" name="authenticity_token" value={form_authenticity_token} />
                    <FormBorderWrapper>
                      <FormField>
                        <label>{t('New Password')}</label>
                        <Field
                          name="user[password]"
                        >
                          {({ input, meta }) => (
                            <div className="right">
                              <input {...input} type="password" autoComplete="password" placeholder="******" />
                              {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || meta.submitError}</span>}
                            </div>
                          )}
                        </Field>
                      </FormField>
                      <FormField>
                        <label>{t('Confirm New Password')}</label>
                        <Field
                          name="user[password_confirmation]"
                          component="input"
                          type="password"
                          autoComplete="current-password"
                          placeholder="******"
                        >
                          {({ input, meta }) => (
                            <div className="right">
                              <input {...input} type="password" placeholder="******" />
                              {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || meta.submitError}</span>}
                            </div>
                          )}
                        </Field>
                      </FormField>        
                    </FormBorderWrapper>
                    
                    <ButtonArea>
                      <div>
                        <BlackBtnInput style={{ marginRight: 10}} type="submit" value={t('Submit')} disabled={submitting || pristine} />
                      </div>
                    </ButtonArea>
                  </form>
                )}
              />      
            </FormArea>
            <LanguageChanger />
          
          </SigninFlexWrapper>
        </CenterFormContainer> 
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    form_authenticity_token: state.form_authenticity_token
  }
}

export default withTranslation()(withRouter(connect(mapStateToProps)(PasswordEdit)));