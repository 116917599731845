
import countryCollections from './country-collections.json';
import _ from 'lodash';
let countryOptions = [];

_.each(countryCollections, opt => {
  
  let countryOption = {
    value: opt.country.tagsId,
    label: opt.country.name
  };

  countryOptions.push(countryOption);
  
  _.each(opt.collections, collection => {
    
    let collectionOption = {
      value: collection.tags_id,
      label: collection.label
    };
    
    countryOptions.push(collectionOption);

  });
});

countryOptions = _.uniqBy(countryOptions, opt => {
  return opt.value;
});

export default countryOptions;