import React, { Component, Fragment, createContext } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom';
import { LeftColumn } from '../components';
import { changeNoticeMessage } from '../actions';
import { withTranslation } from 'react-i18next';
import { ArticleListComp, FeminicideChecker, SendEmailComp } from '../components';
import { DashboardContainer, RightMainColumn, MenuTitle, Title, WhiteBtnLink, Gutter, ProjectArea,
  ProjectTitleArea,
  ProjectTitleLeft,
  ProjectTitle,
  ProjectDelivery,
  ProjectTitleRight,
  FaviconImg,
  ProjectArticleArea,
  ArticleArea,
  ArticleListA,
  ArticleHeader,
  ArticleList,
  ArticleInfoTh,
  ArticleInfo,
  ArticleEventTitle,
  DashboardMenuTitle, 
  FlexSpaceWrapper,
  FlexSpaceWrapperCenter} from '../stylesheets/components';
import moment from 'moment';
import styled from 'styled-components';
import info_svg from '../assets/info.svg';
import ReactTooltip from 'react-tooltip';

const groupBy = (articleEvents) => {
  let pre_articlesEvents = _.values(_.groupBy(articleEvents, ae => ae.aeid));

  return pre_articlesEvents;
}

const DateFormArea = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
` 
const DateFormLabel = styled.div`
  margin-right: 10px;
`
const DateFormInput = styled.div`
  margin-right: 20px;
`

const DateFormFromToArea = styled.div`
  display: flex;
  align-items: center;
`
const DateFormSearchArea = styled.div`
  margin-right: 20px;
`

const SearchTitleInput = styled.input`
  width: 200px;
`
const RevevantInfoArea = styled.div`
  margin-left: 5px;
  margin-top:2px;
`;

const RelevantLabel = styled.div`
  color: #03854B;
`


class ProjectShow extends Component {
  constructor(props){
    super(props);
    this.state = {
      project: null,
      archived: false,
      loadingDate: false,
      activeEventId: null,
      activeEventArticles: [],
      checkAllCheckbox: false,
      // dateRange: [moment("2021-05-21").toDate(), moment("2021-05-25").toDate()],
      dateRange: [moment().subtract(1, 'days').toDate(), new Date()],
      searchTerm: "",
      checkedArticles: {},
      articles: []
    }
  }

  componentDidMount(){
    this.loadData();
    this.loadArticles();
  }


  componentDidUpdate(prevProps){
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.loadData();
      this.loadArticles();
    }
  }

  async loadData(){
    let { t } = this.props;
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    const response = await fetch(`/api/projects/${this.props.match.params.id}.json`, { method: "get", headers });
    if (response.ok) {
      let data = await response.json();
      this.setState({
        project: data.project,
        searchTerm: "",
        // dateRange: [moment("2021-05-21").toDate(), moment("2021-05-23").toDate()]
        dateRange: [moment().subtract(1, 'days').toDate(), new Date()]
      })
    } else {
      let data = await response.json();
      this.props.dispatch(changeNoticeMessage(t("Cannot Find Project", {id: this.props.match.params.id})))
      this.props.history.push(`/${i18n.language}`);
      console.log(response);
    }
  }

  
  handleFilterChange(mode, e){

    if (mode === "fromDate"){
      this.setState({
        dateRange: [e.target.value, this.state.dateRange[1]]
      }, () => this.loadArticles());
    } else if (mode === "toDate") {
      this.setState({
        dateRange: [this.state.dateRange[0], e.target.value]
      }, () => this.loadArticles());
    } else {
      this.loadArticles();
    }

    // if (_.isArray(value)){
    //   this.setState({
    //     dateRange: value
    //   }, () => this.loadArticles());

    // } else {
    //   this.loadArticles();
    // }
  }

  handleCleanCalendar(e){
    this.setState({
      dateRange: []
    }, () => this.loadArticles());
  }

  searchTermCHange(e){
    this.setState({
      searchTerm: e.target.value
    })
  }


  async loadArticles() {

    let { dateRange, searchTerm, archived } = this.state;
    if (!searchTerm && dateRange.length === 0) {
      this.setState({
        project: {
          ...this.state.project,
          article_events_groups: []
        }
      });
      return false;
    }

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    this.setState({
      loadingDate: true
    });

    let finalVariables = {};

    if (searchTerm) {
      finalVariables.search_term = searchTerm;
    }

    if (dateRange.length > 0) {
      if (dateRange[0] !== "") {
        finalVariables.from_date = moment(dateRange[0]).format("YYYY/MM/DD");
      }

      if (dateRange[1] !== "") {
        finalVariables.to_date = moment(dateRange[1]).add(1, "d").format("YYYY/MM/DD"); // to include to date
      }
    }

    finalVariables.archived = archived;

    const response = await fetch(`/api/projects/${this.props.match.params.id}/load_articles.json?` + new URLSearchParams(finalVariables), { method: "get", headers });
    if (response.ok) {
      let data = await response.json();
      this.setState({
        project: {
          ...this.state.project,
          article_events_groups: data.article_events_groups,
        },
        articles: data.articles
      });
      this.setState({
        loadingDate: false
      });
      
    } else {
      let data = await response.json();
      this.props.dispatch(changeNoticeMessage(t("Cannot Find Project", {id: this.props.match.params.id})))
      this.props.history.push(`/${i18n.language}`);
    }
  }


  handleArticleClick(aeid){

    if (aeid === this.state.activeEventId) {
      this.setState({
        activeEventId: null,
        activeEventArticles: []
      })
    } else {
      this.loadActiveEventArticles(aeid);
    }


  }

  async loadActiveEventArticles(aeid){
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    const response = await fetch(`/api/article_events/${aeid}.json`, { method: "get", headers });
    if (response.ok) {
      let data = await response.json();
      // debugger;
      this.setState({
        activeEventArticles: data.article_event.articles,
        activeEventId: aeid
      });
      
    } else {
      let data = await response.json();
      return data;
    }

  }

  handleSelectAllCheckBox(e){
   

    this.setState({
      checkAllCheckbox: !this.state.checkAllCheckbox
    }, () => {
      let newCheckedArticles = {};
      
      if (this.state.checkAllCheckbox) {
        
        _.each(this.state.project.article_events_groups, aeg => {
          newCheckedArticles[aeg.aeid] = aeg.aeid;
        });
      
      } else {
      
        newCheckedArticles = {};
      }
    
      this.setState({
        checkedArticles: newCheckedArticles
      });
    });
  }
  
  handleArchivedToggle(){
    this.setState({
      archived: !this.state.archived
    }, () => {

      this.loadArticles();
    })



  }


  handleArchive(aeid){
    this.handleArchiveUpdate(aeid);
  }

  async handleArchiveUpdate(aeid){
    let { t } = this.props;
    let { searchTerm,  dateRange } = this.state;
    const headers = {
      'X-CSRF-Token': this.props.form_authenticity_token,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    let finalVariables = {};

    if (searchTerm) {
      finalVariables.search_term = searchTerm;
    }

    if (dateRange.length > 0) {
      if (dateRange[0] !== "") {
        finalVariables.from_date = moment(dateRange[0]).format("YYYY/MM/DD");
      }

      if (dateRange[1] !== "") {
        finalVariables.to_date = moment(dateRange[1]).add(1, "d").format("YYYY/MM/DD"); // to include to date
      }
    }


    const response = await fetch(`/api/projects/${this.props.match.params.id}/article_events/archive_update.json`, { 
      method: "post", 
      body: JSON.stringify({ 
        aeid: aeid,
        archived: !this.state.archived,
        search_term: finalVariables.search_term,
        from_date: finalVariables.from_date,
        to_date: finalVariables.to_date
      }), headers });
    if (response.ok) {
      let data = await response.json();
      // debugger;
      this.updateArticleEventGroups(data.article_events_groups);
      this.props.dispatch(changeNoticeMessage(t("Successfully updated.")));

    } else {
      let data = await response.json();

    }

  }

  handleIndividualCheckBox(aeid){
    let newCheckedArticles = {...this.state.checkedArticles};

    if (newCheckedArticles[aeid]){
      // it's already added, so delete
      delete newCheckedArticles[aeid];
    } else {
      // add
      newCheckedArticles[aeid] = aeid;
    }

    this.setState({
      checkedArticles: newCheckedArticles
    });

  } 

  resetIDs(){
    this.setState({
      checkedArticles: {}
    });
  }


  updateArticleEventGroups(article_events_groups) {


    this.setState({
      project: {
        ...this.state.project,
        article_events_groups: article_events_groups
      }
    });
  }

  render() {
    let { project, searchTerm,  dateRange } = this.state;
    let { user_signed_in, t } = this.props;
    let locale = this.props.match.params.locale;
  
    return (
      <Fragment>
        {
          user_signed_in ? 
          <DashboardContainer>
            <LeftColumn />
            <RightMainColumn>
              {
                project ? 
                <ProjectArea>
                  <ProjectTitleArea>
                    <ProjectTitleLeft>
                      <ProjectTitle to={`/${locale}/projects/${project.id}`}>
                        { project.title }
                      </ProjectTitle>
                      <ProjectDelivery>
                        {
                          project.organization_name ?
                          <>
                            By {project.organization_name} —
                          </> : null
                         } {t('Delivering')}&nbsp; 
                            <span className="frequency">
                              {
                                project.email_frequency === "Daily" ? 
                                t("once a day") :
                                t("once a week")
                              }
                            </span> 
                      </ProjectDelivery>
                    </ProjectTitleLeft>
                    <ProjectTitleRight>
                      <WhiteBtnLink to={`/${locale}/projects/${project.id}/edit`}>
                        {t('Edit Setting')}
                      </WhiteBtnLink>
                    </ProjectTitleRight>
                  </ProjectTitleArea>

                  <ProjectArticleArea>
                    
                    <DashboardMenuTitle>
                      News Articles
                    </DashboardMenuTitle>
                    <DateFormArea>

                      <DateFormLabel>
                        <b>{t("Search")}</b>
                      </DateFormLabel> 
                      <DateFormSearchArea>
                        <SearchTitleInput type="text" value={searchTerm} onBlur={this.handleFilterChange.bind(this)} onChange={this.searchTermCHange.bind(this)} placeholder={`${t('Enter Title')}...`}/>
                      </DateFormSearchArea>

                      <DateFormLabel>
                        <b>{t("Collected Date")}</b>
                      </DateFormLabel> 
                      {/* <DateFormFromToArea>
                        <DateRangePicker value={dateRange} onClean={this.handleCleanCalendar.bind(this)} onChange={this.handleFilterChange.bind(this)} />
                      </DateFormFromToArea> */}

                      <DateFormFromToArea>
                        <DateFormLabel>
                          {t("From")}
                        </DateFormLabel>
                        <DateFormInput>
                          <input type="date" onChange={this.handleFilterChange.bind(this, "fromDate")} max={moment().format("MMM DD, YYYY")} value={moment(dateRange[0]).format("YYYY-MM-DD")} />
                        </DateFormInput>

                        <DateFormLabel>
                          {t("To")}
                        </DateFormLabel>

                        <DateFormInput>
                          <input type="date" onChange={this.handleFilterChange.bind(this, "toDate")} max={moment().format("MMM DD, YYYY")} value={moment(dateRange[1]).format("YYYY-MM-DD")} />
                        </DateFormInput>
                        <DateFormLabel>
                          <b>{t("Archived")}</b>
                        </DateFormLabel> 

                        <DateFormInput>
                          <input type="checkbox"  onChange={this.handleArchivedToggle.bind(this)} />
                        </DateFormInput>
                      </DateFormFromToArea>
                    </DateFormArea>
                    
                    {/* {
                      project.opt_in_improving_model && _.values(this.state.checkedArticles).length > 0 ? 
                      <FeminicideChecker 
                        updateArticleEventGroups={this.updateArticleEventGroups.bind(this)}
                        project={project} 
                        dateRange={this.state.dateRange} 
                        searchTerm={this.state.searchTerm} 
                        resetIDs={this.resetIDs.bind(this)} checkedArticles={this.state.checkedArticles} />
                      : <></>
                    } */}
                    {
                      _.values(this.state.checkedArticles).length > 0 ?
                      <SendEmailComp 
                      updateArticleEventGroups={this.updateArticleEventGroups.bind(this)}
                      project={project} 
                      resetIDs={this.resetIDs.bind(this)} checkedArticles={this.state.checkedArticles} /> : <></>
                    }
                    
                  
                  
                    {
                      this.state.loadingDate ? 
                      <>
                        Loading...
                      </> : 
                      <>
                        {
                          project.article_events_groups.length > 0 ? 
                            <ArticleArea>

                              <thead>
                                <ArticleHeader>
                                  <ArticleInfoTh className="head checkbox" style={{"whiteSpace": "pre-line"}}>
                                    {this.state.archived ? t("Unarchive") : t("Archive")}
                                  </ArticleInfoTh>
                                  <ArticleInfoTh className="head checkbox" style={{"whiteSpace": "pre-line"}}>
                                    {t("Email")}
                                    {/* <input type="checkbox" name="select_all_checkbox" onClick={this.handleSelectAllCheckBox.bind(this)} /> */}
                                  </ArticleInfoTh>
                                  <ArticleInfoTh className="head title">
                                    {t("Title")}
                                  </ArticleInfoTh>
                                  <ArticleInfoTh className="head date">
                                    {t("Publish Date")}
                                  </ArticleInfoTh>
                                  <ArticleInfoTh className="head date">
                                    {t("Collected Date")}
                                  </ArticleInfoTh>
                                  <ArticleInfoTh className="head count">
                                    {t("Count")}
                                  </ArticleInfoTh>
                                  {
                                    project.opt_in_improving_model ? 
                                    <ArticleInfoTh className="head checker">
                                      <FlexSpaceWrapperCenter>
                                        <RelevantLabel>
                                          Relevant? 
                                        </RelevantLabel>
                                        <RevevantInfoArea>
                                          <img src={info_svg} alt="info"  data-tip="Telling us which articles are relevant to your project <br/> helps us train the system to send you better information."/> 
                                          <ReactTooltip multiline={true} />
                                        </RevevantInfoArea>
                                      </FlexSpaceWrapperCenter>
                                    </ArticleInfoTh> :
                                    null
                                  }
                                  {/* <ArticleInfo className="head conf">
                                    {t("Confidence")}
                                  </ArticleInfo> */}
                                </ArticleHeader>
                              </thead>     
                              <tbody>
                                    
                                {
                                  _.map(project.article_events_groups, (article_event) => {
                                    let articles = _.filter(this.state.articles, a => a.article_event_id === article_event.aeid);
                                    
                                    return <ArticleListComp 
                                      key={article_event.aeid}
                                      articles={articles}
                                      project_id={project.id}
                                      archived={this.state.archived}
                                      opt_in_improving_model={project.opt_in_improving_model}
                                      handleIndividualCheckBox={this.handleIndividualCheckBox.bind(this)}
                                      activeEventArticles={this.state.activeEventArticles}
                                      activeEventId={this.state.activeEventId}
                                      checkAllCheckbox={this.state.checkAllCheckbox}
                                      checkedArticles={this.state.checkedArticles}
                                      handleArchive={this.handleArchive.bind(this)}
                                      handleArticleClick={this.handleArticleClick.bind(this)}
                                      article_event={article_event} />
                                  })
                                }
                              </tbody>
                            </ArticleArea>
                            : <div>
                              {t('No recent news articles.')}
                            </div> 
                        }
                      </>
                    }
                  </ProjectArticleArea>
                  <Gutter h={100} />
                </ProjectArea> : null
              }
            </RightMainColumn>
          </DashboardContainer> : null
        }        
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    form_authenticity_token: state.form_authenticity_token,
    user_signed_in: state.user_signed_in,
    current_user: state.current_user
  }
}

export default withTranslation()(withRouter(connect(mapStateToProps)(ProjectShow)));