import React, { Component } from 'react'
import styled from 'styled-components';
import { connect } from 'react-redux';
import { changeNoticeMessage } from '../actions';
import { FlexWrapper, FlexSpaceWrapper, MenuTitle, Gutter, GreenBtn } from '../stylesheets/components';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

const SettingArea = styled.div`

`;


const EditMenuTitle = styled(MenuTitle)`
  margin-bottom: 5px;
`;

const SettingAreaSubtitle = styled.div`
  font-style: italic;
  color: #777;
`
class CollectionConfirm extends Component {
  constructor(props){
    super(props);
    this.state = {
    };
  }



  async handleToggleCollection(value) {
    let { project, t } = this.props;

    const headers = {
      'X-CSRF-Token': this.props.form_authenticity_token,
      'X-Requested-With': 'XMLHttpRequest'
    }

    let formData = new FormData();    
    formData.append("project[confirmed_collection]", value);
    formData.append("project[confirmed_at]", new Date().toUTCString());

    console.log(value)
    const response = await fetch(`/api/projects/${project.id}.json`, { method: "put", body: formData, headers });
    if (response.ok) {
      let data = await response.json();

      if (value) {

        this.props.dispatch(changeNoticeMessage(t("Success Start Collection")));

      } else {

        this.props.dispatch(changeNoticeMessage(t("Success Cancel Collection")));
        
      }
      this.props.updateProject(data.project);

    } else {
      let data = await response.json();

      // this.props.dispatch(changeProgress(false));
      return data;

    }
  }
  

  render() {
    let { project, t } = this.props;
    return (
      <SettingArea>
        {
          project.confirmed_collection ? 
          <>
            <EditMenuTitle>
              {t("Cancel Collecting News Articles")}
            </EditMenuTitle>
            
            <Gutter h={10} />
            {t("Start Collection Date")} { moment(project.confirmed_at).format("MM/DD/YYYY, HH:mm") }.<br/><br/>
            
            <GreenBtn data-confirm={t("Are you sure?")} onClick={this.handleToggleCollection.bind(this, false)}>
              {t("Cancel Collection")}
            </GreenBtn>
          </>
          :
          <>
            <EditMenuTitle>
              {t("Start Collecting News Articles")}
            </EditMenuTitle>
            
            <Gutter h={10} />
            <GreenBtn data-confirm={t("Are you sure?")} onClick={this.handleToggleCollection.bind(this, true)}>
              {t("Start Collection")}
            </GreenBtn>
          </>

        }
        
      </SettingArea>
    )
  }
}

let mapStateToProps = state => {
  return {
    user_signed_in: state.user_signed_in,
    current_user: state.current_user,
    form_authenticity_token: state.form_authenticity_token
  }
}
export default withTranslation()(connect(mapStateToProps)(CollectionConfirm));