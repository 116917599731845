import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Container, FlexSpaceWrapper, Gutter, CenterFormContainer, FormArea, MenuTitle, PageTitle, FormField, FormBorderWrapper, ButtonArea, BlackBtnInput, WhiteBtnA } from '../stylesheets/components';
import Routes from '../constants/routes';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { SearchPersonMapContainer } from '../components';
import { numberWithDelimiter, tablize } from '../utils';
import _ from 'lodash';
import { logIn, changeNoticeMessage, updateAuthenticityToken } from '../actions';
import { Form, Field } from 'react-final-form';
import {LanguageChanger } from '../components';
import { withTranslation } from 'react-i18next';
import logo_en from "../assets/logo_en.svg";
import logo_es from "../assets/logo_es.svg";


const Fragment = React.Fragment;

const SigninFlexWrapper = styled.div`
  width: 100%;
`;

class Signup extends Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }
  componentDidMount(){
    // this.loadData();
  }


  async handleSubmit(values) {
    let locale = this.props.match.params.locale;
    const headers = {
      'X-CSRF-Token': this.props.form_authenticity_token,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
    // this.props.dispatch(changeProgress(true));
    const response = await fetch(Routes.api_user_registration_path({format: "json"}), { method: "post", body: JSON.stringify(values), headers });
    if (response.ok) {
      let data = await response.json();
      this.props.dispatch(logIn(data.user, data.csrf_token));
      this.props.dispatch(changeNoticeMessage("Successfully Signed Up."))
      // this.props.dispatch(changeProgress(false));
      this.props.history.push(`/${locale}`)
      return true;
    } else {
      let data = await response.json();
      // this.props.dispatch(changeProgress(false));
      return data;
    }
  }



  handleValidate(values) {
    const errors = {};
    let { t } = this.props;
    if (values.user) {

      if (!values.user.fullname) {
        if (!errors.user) {
          errors.user = {};
        }
        errors.user.fullname = t('Required');
      }

      if (!values.user.email) {
        if (!errors.user) {
          errors.user = {};
        }
        console.log(values);
        errors.user.email = t('Required');
      }

      if (!values.user.password) {
        if (!errors.user) {
          errors.user = {};
        }
        errors.user.password = t('Required');
      }
      

      if (!values.user.password_confirmation) {
        if (!errors.user) {
          errors.user = {};
        }
        errors.user.password_confirmation = t('Required');
      }

      if (values.user.password !== values.user.password_confirmation) {
        if (!errors.user) {
          errors.user = {};
        }
        errors.user.password_confirmation = t("Should match with the password");
      }



    }
    return errors;
  }

  render() {
    let { windowWidth, form_authenticity_token, flash_alert, t } = this.props;
    let locale = this.props.match.params.locale;
    return (
      <Fragment>
        <CenterFormContainer>
          <SigninFlexWrapper>
            <PageTitle>
              <img src={locale === "en" ? logo_en : logo_es } alt="Data Against Feminicide: Email Alert" />
            </PageTitle>

            <FormArea>
              <MenuTitle>
                {t('Sign Up')}
              </MenuTitle>
              <Form
                onSubmit={this.handleSubmit.bind(this)}
                validate={this.handleValidate.bind(this)}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                  <form method="post" onSubmit={handleSubmit}>
                    <FormBorderWrapper>
                      <FormField>
                        <label>{ t('Email') }</label>
                        <Field
                          name="user[email]"
                          component="input"
                          type="email"
                          autoFocus={true}
                          autoComplete="email"
                          placeholder="person@mit.edu"
                        >
                          {({ input, meta }) => (
                            <div className="right">
                              <input {...input} type="email" autoComplete="email" placeholder="e.g., person@mit.edu" />
                              {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || meta.submitError}</span>}
                            </div>
                          )}
                        </Field>
                      </FormField>
                      <FormField>
                        <label>{ t('Full Name') }</label>
                        <Field
                          name="user[fullname]"
                          component="input"
                          type="text"
                          autoFocus={false}
                          autoComplete="fullname"
                          placeholder="e.g., Wonyoung So"
                        >
                          {({ input, meta }) => (
                            <div className="right">
                              <input {...input} type="text" autoComplete="fullname" placeholder="e.g., Wonyoung So" />
                              {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || meta.submitError}</span>}
                            </div>
                          )}
                        </Field>
                      </FormField>
                      <FormField>
                        <label>{ t('Password') }</label>
                        <Field
                          name="user[password]"
                          component="input"
                          type="password"
                          autoComplete="current-password"
                          placeholder="******"
                        >
                          {({ input, meta }) => (
                            <div className="right">
                              <input {...input} type="password" placeholder="******" />
                              {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || meta.submitError}</span>}
                            </div>
                          )}
                        </Field>
                      </FormField>
                      <FormField>
                        <label>{ t('Password Confirmation') }</label>
                        <Field
                          name="user[password_confirmation]"
                          component="input"
                          type="password"
                          autoComplete="current-password"
                          placeholder="******"
                        >
                          {({ input, meta }) => (
                            <div className="right">
                              <input {...input} type="password" placeholder="******" />
                              {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || meta.submitError}</span>}
                            </div>
                          )}
                        </Field>
                      </FormField>
                    </FormBorderWrapper>
                    
                    <ButtonArea>
                      <div>
                        <BlackBtnInput style={{ marginRight: 10}} type="submit" value={t('Submit')} disabled={submitting || pristine} />
                        <WhiteBtnA href={`/${locale}/users/sign_in`}>
                          {t('Sign In')}
                        </WhiteBtnA>
                      </div>
                    </ButtonArea>
                  </form>
                )}
              />      
            </FormArea>
            <LanguageChanger />
          
          </SigninFlexWrapper>
        </CenterFormContainer> 
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    form_authenticity_token: state.form_authenticity_token,
    flash_alert: state.flash_alert,
    flash_notice: state.flash_notice
  }
}

export default withTranslation()(withRouter(connect(mapStateToProps)(Signup)));