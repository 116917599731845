import React from 'react';
import { windowResize, changeMatchedPeople } from '../actions';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { matchPath} from 'react-router';
import { Route, Switch, Redirect } from "react-router-dom";
import { withRouter } from 'react-router';
import _ from 'lodash';
import FirstPage from './FirstPage';
import SetupConfig from './SetupConfig';
import { Notice } from '../components';
import Signin from './Signin';
import Dashboard from './Dashboard';
import Signup from './Signup';
import PasswordNew from './PasswordNew';
import PasswordEdit from './PasswordEdit';
import ProjectNew from './ProjectNew';
import ProjectEdit from './ProjectEdit';
import ProjectShow from './ProjectShow';
import MediaListShowPage from './MediaListShowPage';
import About from './About';
import { updateSessionInfo } from '../actions';
import { Gutter } from '../stylesheets/components';
import Routes from "../constants/routes";
import { withTranslation } from 'react-i18next';


const Fragment = React.Fragment;
class Welcome extends React.Component {

  constructor(props) {
    super(props);
  }
  
  componentDidMount(){
    window.addEventListener('resize', this.resizeHandler.bind(this));
    this.initI18n();
    this.resizeHandler();
    this.getSessionData();
  }

  initI18n(){
    const match = matchPath(this.props.location.pathname, {
      path: "/:locale"
    });
    let locale = match.params.locale;
    let { i18n } = this.props;
    
    if (i18n.language != locale) {
      i18n.changeLanguage(match.params.locale);
    }
  }

  async getSessionData(){

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    // this.props.dispatch(changeProgress(true));
    const response = await fetch("/api/session_info.json", { method: "get", headers });
    if (response.ok) {
      let data = await response.json();
      this.props.dispatch(updateSessionInfo(data.enable_create_account, data.user_signed_in, data.current_user, data.form_authenticity_token))
    } else {
      // let data = await response.json();
      console.log(response);
    }
  }


  resizeHandler(e){
    this.props.dispatch(windowResize({
      width: window.innerWidth,
      height: window.innerHeight
    }));
    
  }

  componentDidUpdate(){
  }


  renderWithAuthentication(children){
    let { sessionSynced, user_signed_in } = this.props;
    const match = matchPath(this.props.location.pathname, {
      path: "/:locale"
    });
    let locale = match.params.locale;
    
    if (!sessionSynced) {
      return children;
      
    } else {
      return user_signed_in ? children 
      : <Redirect to={`/${locale}/users/sign_in`} />;
    }

  }



  render() {
  
    let { noticeMessage, enable_create_account } = this.props;
    const match = matchPath(this.props.location.pathname, {
      path: "/:locale"
    });
    let locale = match.params.locale;

    return (
      <Fragment>
        {
          noticeMessage ? 
          <Notice /> : null
        }
        <Switch>


          <Route exact path="/:locale">
            {
              this.renderWithAuthentication(<Dashboard />)
            }
          </Route>
          
          <Route exact path="/:locale/about">
            {
              this.renderWithAuthentication(<About />)
            }
          </Route>
          <Route exact path="/:locale/projects/new">
            {
              this.renderWithAuthentication(<ProjectNew />)
            }
          </Route>

          <Route exact path="/:locale/projects/:id/edit">
            {
              this.renderWithAuthentication(<ProjectEdit />)
            }
          </Route>


          <Route exact path="/:locale/projects/:id/media_lists">
            {
              this.renderWithAuthentication(<MediaListShowPage />)
            }
          </Route>

          <Route exact path="/:locale/projects/:id">
            {
              this.renderWithAuthentication(<ProjectShow />)
            }
          </Route>



          

          <Route exact path="/:locale/users/sign_in">
            <Signin />
          </Route>

          <Route exact path="/:locale/users/sign_up">
            <Signup /> 
          </Route>

          <Route exact path="/:locale/users/password/new">
            <PasswordNew />
          </Route>


          <Route exact path="/:locale/users/password/edit">
            <PasswordEdit />
          </Route>

        </Switch>

        
      </Fragment>
    );
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    enable_create_account: state.enable_create_account,
    windowHeight: state.windowHeight,
    user_signed_in: state.user_signed_in,
    sessionSynced: state.sessionSynced,
    noticeMessage: state.noticeMessage
  }
}

export default withTranslation()(withRouter(connect(mapStateToProps)(Welcome)));