import React, { Component } from 'react'
import styled from 'styled-components';
import { connect } from 'react-redux';
import { changeNoticeMessage } from '../actions';
import { FlexWrapper, FlexSpaceWrapper, MenuTitle, Gutter, GreenBtn } from '../stylesheets/components';
import AsyncSelect from 'react-select/async';
import { withTranslation } from 'react-i18next';

const SettingArea = styled.div`

`;

const EditMenuTitle = styled(MenuTitle)`
  margin-bottom: 5px;
`;

const SettingAreaSubtitle = styled.div`
  font-style: italic;
  color: #777;
`

const ErrorArea = styled.div`
  color: red;
`;

const ProjectAccessArea = styled(FlexWrapper)`
  width: 500px;
  padding: 10px 0;
  border-top: 1px solid #ccc;
  &:last-child {
    border-bottom: 1px solid #ccc;
  }
`

const ProjectAccessEmail = styled.div`
  width: calc(100% - 100px);
`
const ProjectAccessPermission = styled.div`
  width: 150px;
`
const ProjectAccessRemove = styled.div`
  width: 100px;
  text-align: right;
`

const SelectArea = styled.div`
`;

const SelectInput = styled.input`
  width: 200px;
  margin-right: 10px;
`;

const validateEmailFunc = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

class Sharing extends Component {
  constructor(props){
    super(props);
    this.state = {
      selectedEmail: "",
      selectedUser: null
    };
  }



  async handleAddPerson(e) {
    let { project, t } = this.props;
    let { selectedEmail } = this.state;

    const headers = {
      'X-CSRF-Token': this.props.form_authenticity_token,
      'X-Requested-With': 'XMLHttpRequest'
    }

    let formData = new FormData();    
    formData.append("email", selectedEmail);

    // this.props.dispatch(changeProgress(true));
    const response = await fetch(`/api/projects/${project.id}/add_person.json`, { method: "post", body: formData, headers });
    if (response.ok) {
      let data = await response.json();
      this.props.dispatch(changeNoticeMessage(t("Successful Add Share", { label: selectedEmail})));
      this.props.updateProject(data.project);
      this.setState({
        selectedEmail: ""
      });

    } else {
      let data = await response.json();
      this.props.dispatch(changeNoticeMessage(t(data.errors)));

      // this.props.dispatch(changeProgress(false));
      return data;

    }


  }
  async loadUsers(email){
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }


    const response = await fetch(`/api/users/search.json?query=${email}`, { method: "get", headers });
    if (response.ok) {
      let data = await response.json();

      return _.map(data.users, user => {
        return {
          label: `${user.fullname} (${user.email})`,
          value: user.id
        }
      })
    } else {
      let data = await response.json();
      console.log(data);
    }    
  }

  handleInputChange(e){
    console.log(e);
    this.setState({ 
      selectedUser: e
    });
  }
  
  async handleRemovePerson(id, e){
    e.preventDefault();
    let { project } = this.props;

    const headers = {
      'X-CSRF-Token': this.props.form_authenticity_token,
      'X-Requested-With': 'XMLHttpRequest'
    }

    let formData = new FormData();    
    formData.append("project_access_id", id);

    // this.props.dispatch(changeProgress(true));formData
    const response = await fetch(`/api/projects/${project.id}/remove_person.json`, { method: "delete", body: formData, headers });
    if (response.ok) {
      let data = await response.json();

      this.props.dispatch(changeNoticeMessage(`Successfully removed.`));
      this.props.updateProject(data.project);

    } else {
      let data = await response.json();

      // this.props.dispatch(changeProgress(false));
      return data;

    }

  }
  handleInputManualChange(e){
    this.setState({ 
      selectedEmail: e.target.value
    });
  }
  isCurrentUserOwner(){
    let { project, current_user } = this.props;

    if (current_user.superuser) {
    
      return true;
    
    } else {
      
      let pa = _.find(project.project_accesses, pa => pa.user_id === current_user.id);
    
      if (pa) {
        return pa.permission === "owner";
      } else {
        return false;
      }

    }
    
    
  }

  validateEmail(){
    let { selectedEmail } = this.state;
    return validateEmailFunc(selectedEmail);
  }

  render() {
    let { project, t } = this.props;
    return (
      <SettingArea>
        <EditMenuTitle>
          {t("Share with specific people")}
        </EditMenuTitle>
        
        <Gutter h={10} />
        <div>
          {
            _.map(project.project_accesses, pa => {
              return (
                <ProjectAccessArea key={pa.id}>
                  <ProjectAccessEmail>
                    {pa.fullname } ({ pa.email })
                  </ProjectAccessEmail>
                  <ProjectAccessPermission>
                    { t(pa.permission.charAt(0).toUpperCase() + pa.permission.slice(1))  }
                  </ProjectAccessPermission>
                  <ProjectAccessRemove>
                    {
                      pa.permission !== "owner" && this.isCurrentUserOwner() ? 
                      <a href="#" data-confirm={t("Are you sure?")} onClick={this.handleRemovePerson.bind(this, pa.id)}>{t("Remove")}</a> : null
                    }
                  </ProjectAccessRemove>
                </ProjectAccessArea>
              )
            })
          }
        </div>
        <Gutter h={10} />
        <FlexWrapper>
          <SelectArea>
            <SelectInput type="email" placeholder={t("Type Email")} value={this.state.selectedEmail} onChange={this.handleInputManualChange.bind(this)} />
            {/* <AsyncSelect
              backspaceRemovesValue={true}
              isClearable={true}
              value={this.state.selectedUser}
              onChange={this.handleInputChange.bind(this)}
              placeholder={t("Type Email")}
              loadOptions={this.loadUsers}

              styles={{
                indicatorsContainer: (provided, state) => ({
                  ...provided,
                  display: "none"
                })
              }}
            /> */}
          </SelectArea>

          <GreenBtn onClick={this.handleAddPerson.bind(this)} disabled={!this.validateEmail()}>
            + {t("Add new person")}
          </GreenBtn>
        </FlexWrapper>
      </SettingArea>
    )
  }
}

let mapStateToProps = state => {
  return {
    user_signed_in: state.user_signed_in,
    current_user: state.current_user,
    form_authenticity_token: state.form_authenticity_token
  }
}
export default withTranslation()(connect(mapStateToProps)(Sharing));