import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux';
import { changeNoticeMessage } from '../actions';
import { withRouter, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { 
  DashboardMenuTitle, GreenBtn } from '../stylesheets/components';


const InputWhite = styled.input`
  width: 280px;
  margin-right: 10px;
`;

const Area = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
` 
const Title = styled.div`
  margin-right: 10px;

`
const WGutter = styled.div`
  width: ${props => props.w}px;
`

class SendEmailComp extends Component {
  constructor(props){
    super(props);
    this.state = {
      recipients: ""
    }
  }

  async handSendEmail(e){

    if (this.state.recipients.length === 0) {
      alert("You must enter the recipients' email.")
      return false;
    }

    let { t } = this.props;
    const headers = {
      'X-CSRF-Token': this.props.form_authenticity_token,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
    const response = await fetch(`/api/projects/${this.props.project.id}/article_events/bulk_gather.json`, { 
      method: "post", 
      body: JSON.stringify({ 
        article_event_ids: _.values(this.props.checkedArticles)
      }), headers });
    if (response.ok) {
      let data = await response.json();
      this.fireEmail(data);
    } else {
      let data = await response.json();

    }
  }

  fireEmail(data){

    let { current_user } = this.props;
    let { recipients } = this.state;
    let len = _.values(this.props.checkedArticles).length;

    let body_str = ``;
    _.each(data.article_events, ae => {
      let articleEventStr = `---
Title: ${ae.title}

`;

      body_str += articleEventStr;

      _.each(ae.articles, article => {
        let articleStr = `From ${article.media_name}: 
      ${article.url}

`;
      
        body_str += articleStr;
      });

      body_str += `

`;
      
    });
    
    window.open(`mailto:${recipients}?subject=Found ${len} Article${len > 1 ? "s" : ""}&body=${escape(body_str)}`, '_blank');
  }

  handleRecipientsChange(e){
    this.setState({
      recipients: e.target.value
    });
  }

  render() {
    let { isRelevant } = this.state;
    let { t } = this.props;
    let len = _.values(this.props.checkedArticles).length;
    return (
      <>
        <DashboardMenuTitle>
          Share News Articles
        </DashboardMenuTitle>

        <Area>
          <InputWhite type="email" placeholder="ex) someone@gmail.com; another@gmail.com" value={this.state.recipients} onChange={this.handleRecipientsChange.bind(this)} name="repicients"/>
          <GreenBtn data-confirm={t(`Email ${len} articles to ${this.state.recipients}?`)} onClick={this.handSendEmail.bind(this)}>
            Share
          </GreenBtn>
        </Area>
      </>
    )
  }
}

let mapStateToProps = state => {
  return {
    form_authenticity_token: state.form_authenticity_token,
    current_user: state.current_user
  }
}

export default withTranslation()(withRouter(connect(mapStateToProps)(SendEmailComp)));