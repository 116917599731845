import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux';
import { changeNoticeMessage } from '../actions';
import { withRouter, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { 
  DashboardMenuTitle, GreenBtn } from '../stylesheets/components';


const Area = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
` 
const Title = styled.div`
  margin-right: 10px;

`
const WGutter = styled.div`
  width: ${props => props.w}px;
`

class FeminicideChecker extends Component {
  constructor(props){
    super(props);
    this.state = {
      isRelevant: "none"
    }
  }
  handleSelectUpdate(e){
    this.setState({
      isRelevant: e.target.value
    })
  }

  async handleUpdate(e){
    let { t, dateRange, searchTerm } = this.props;
    const headers = {
      'X-CSRF-Token': this.props.form_authenticity_token,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    if (this.state.isRelevant === "none") {
      alert("You need to choose yes or no");
      return false;
    }

    let finalVariables = {};

    if (searchTerm) {
      finalVariables.search_term = searchTerm;
    }

    if (dateRange.length > 0) {
      if (dateRange[0] !== "") {
        finalVariables.from_date = moment(dateRange[0]).format("YYYY/MM/DD");
      }

      if (dateRange[1] !== "") {
        finalVariables.to_date = moment(dateRange[1]).format("YYYY/MM/DD");
      }
    }

    const response = await fetch(`/api/projects/${this.props.project.id}/article_events/bulk_update.json`, { 
      method: "post", 
      body: JSON.stringify({ 
        is_relevant: this.state.isRelevant === "yes" ? true : false,
        article_event_ids: _.values(this.props.checkedArticles),
        search_term: finalVariables.search_term,
        from_date: finalVariables.from_date,
        to_date: finalVariables.to_date
      }), headers });
    if (response.ok) {
      let data = await response.json();
      this.props.updateArticleEventGroups(data.article_events_groups);
      this.setState({
        isRelevant: "none"
      });

      this.props.resetIDs();
      this.props.dispatch(changeNoticeMessage(t("Successfully updated.")));

    } else {
      let data = await response.json();

    }

  }



  render() {
    let { isRelevant } = this.state;
    let { t } = this.props;
    let len = _.values(this.props.checkedArticles).length;
    return (
      <>
        <DashboardMenuTitle>
          Feminicide Relevance Check
        </DashboardMenuTitle>

        <Area>
          <Title>
            <b>Are the {len} article{len > 1 ? "s" : ""} you checked relevant to this project?</b>
          </Title>

          <select value={isRelevant} onChange={this.handleSelectUpdate.bind(this)} name="isRelevant">
            <option value="none" disabled hidden>
              Select an Option
            </option>
            <option value="yes">
              Yes
            </option>
            <option value="no">
              No
            </option>   
          </select>
          <WGutter w={10}/>
          <GreenBtn data-confirm={t("Are you sure?")} onClick={this.handleUpdate.bind(this)}>
            Submit
          </GreenBtn>
        </Area>
      </>
    )
  }
}

let mapStateToProps = state => {
  return {
    form_authenticity_token: state.form_authenticity_token
  }
}

export default withTranslation()(withRouter(connect(mapStateToProps)(FeminicideChecker)));