import styled from 'styled-components';
import mixins from './mixins';
import { Link } from 'react-router-dom'

const FlexWrapper = styled.div`
  display: flex;
`;

const FlexSpaceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FlexSpaceWrapperCenter = styled(FlexSpaceWrapper)`
  align-items: center;
`;


const CenterFormContainer = styled.div`
  width: 500px;
  margin: 0 auto;
  display: flex;
  height: 100vh;
  justify-content: center;
	align-items: center;
`;

const SearchLayout = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const MenuTitle = styled.h2`
  font-family: "GT America";
  font-weight: 400;
  color: #03854B;
  margin-bottom: 15px;
  line-height: 1;
  font-size:1.5em;
`;


const BlackBtnInput = styled.input`
  background: #03854B;
  border:none;
  border-radius: 5px;
  padding: 10px 20px;
  font-family: 'Source Sans Pro';
  font-size:1.1em;
  font-weight: 600;
  color: white;
  transform-origin: center;
  max-width: 300px;
  cursor:pointer;
  text-align: center;
  font-size:1.1em;

  &:disabled {
    background-color: #888;
    color: #777;
    cursor: not-allowed;
    &:hover {
      background-color: #888;
      color: #777;
      opacity: 1;
      cursor: not-allowed;
    }

  }
  &:hover {
    opacity: 0.7;
  }

  &.search {
    width: calc(30% - 20px);
  }
`;

const GreenBtn = styled.button`
  background: #03854B;
  border:none;
  border-radius: 5px;
  padding: 10px 20px;
  font-family: 'Source Sans Pro';
  font-size:1.1em;
  font-weight: 600;
  color: white;
  transform-origin: center;
  max-width: 200px;
  cursor:pointer;
  text-align: center;
  font-size:1.1em;

  &:disabled {
    background-color: #888;
    color: #777;
    cursor: not-allowed;
    &:hover {
      background-color: #888;
      color: #777;
      opacity: 1;
      cursor: not-allowed;
    }

  }
  &:hover {
    opacity: 0.7;
  }

  &.search {
    width: calc(30% - 20px);
  }
`;




const Gutter = styled.div`
  height: ${props => props.h}px;
`;

const FaviconImg = styled.img`
  position: relative; 
  top:5px;
`;

const TextInput = styled.input`
  font-family: "Source Sans Pro";
  font-size: 1.286em;
  color: black;
  padding: 15px 10px;
  border: 2px solid black;
  border-radius: 5px;

  &.search {
    width: calc(70% - 20px);
  }
`;



const Container = styled.div`
  width: 100%;
`;
const DashboardContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;


const WhiteBtn = styled.button`
  background: #FFFFFF;
  border: 1px solid #000000;
  border-radius: 5px;
  padding: 10px;
  font-family: 'Source Sans Pro';
  font-size:1.2em;
  font-weight: 700;
  transform-origin: center;
  margin-right: 10px;
  max-width: 200px;
  cursor:pointer;
  text-align: center;
  font-size:1.1em;

  &:hover {
    opacity: 1;
    background: black;
    color: white;
    border: 1px solid #444;
  }
`;

const FormBorderWrapper = styled.div`
`;


const ButtonArea = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h1`
  font-family: "Uniform Extra Condensed Bold";
  font-weight: 400;
  line-height: 1;
  font-size: 3.8em;
  color:#03854B;
`;

const PageTitle = styled(Title)`

`;

const WhiteBtnA = styled.a`
  background: #E1DED7;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px 20px;
  font-family: 'Source Sans Pro';
  font-size:1.1em;
  font-weight: 600;
  color: #777;
  transform-origin: center;
  cursor:pointer;
  text-align: center;
  font-size:1.1em;
  text-decoration: none;

  &:hover {
    opacity: 0.7;
  }
`;

const WhiteBtnLink = styled(Link)`
  display: inline-block;
  background: #EEE;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px 20px;
  font-family: 'Source Sans Pro';
  font-size:1.1em;
  font-weight: 600;
  color: #777;
  transform-origin: center;
  cursor:pointer;
  text-align: center;
  font-size:1.1em;
  text-decoration: none;

  &:hover {
    opacity: 0.7;
  }
`;


const FormField = styled.div`
  margin-bottom: 15px;

  input[type=text], input[type=email], input[type=password] {
    width: calc(100% - 30px);
  }

  label {
    color: #707070;
    display: block;
    font-weight: 600;
    margin-bottom: 8px;
  }

  div.right {
    font-size: 1.0em;
    color: black;
    background:transparent;
  }

  span.error {
    display: block;
    margin-top: 5px;
    color: red;
    font-weight: 600;
  }
`;

const Padding = styled.div`
  padding: 20px;
`;

const RightMainColumn = styled.div`
  width: calc(100% - 285px);
  padding: 30px;
`;

const ProjectArea = styled.div`
`
const ProjectTitleArea = styled.div`
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #CCC;

  display: flex;
  justify-content: space-between;
  align-items: center;
`
const ProjectTitleLeft = styled.div`

`
const ProjectTitle = styled(Link)`
  font-family: "Uniform Extra Condensed Bold";
  font-weight: 400;
  line-height: 1;
  font-size: 3.2em;
  text-transform: uppercase;
  color:#03854B;
  text-decoration: none;
`;

const ProjectDelivery = styled.div`
  span.frequency {
    color: black;
  }
`
const ProjectTitleRight = styled.div``
const ProjectArticleArea = styled.div``
const ArticleArea = styled.table`
  margin: 10px 0;
  width: 100%;
`

const ArticleEventTitle = styled.h3`
  font-family: "GT America";
  font-weight: 400;
  line-height: 1.4;
  font-size: 1.5em;
  color:#03854B;
  margin-bottom: 10px;
  text-decoration: none;
`;

const ArticleHeader = styled.tr`
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
`
const ArticleListA = styled.a`
  text-decoration: none;
  border-top: 1px solid #ccc;

  &:last-child {
    border-bottom: 1px solid #ccc;
  }


`

const ArticleList = styled.tr`
  
  text-decoration: none;
  cursor:pointer;
  border-top: 1px solid #eaeaea;
  
   &:last-child {
    border-bottom: 1px solid #ccc;
  }

  &:first-child {
    border:none;
  }



`

const ArticleInfoTh = styled.th`
  padding: 10px 0;
  text-align: left;
  &.checkbox {
    padding-right: 10px;
  }
  &.head {
    font-weight: 600;
    color: #03854B;
  }

  &.title {
  }

  &.media {
    padding-right: 20px;
  }

  &.date {
    padding-right: 20px;
  }

  &.count {
    padding-right: 20px;
  }

  &.conf {
    width: 75px;
    text-align: right;
  }


  &.checker {
    width: 73px;
  }

`
const ArticleInfo = styled.td`
  padding: 5px 0;
  &.checkbox {
    padding-right: 20px;
    text-align: center;
  }
  &.head {
    font-weight: 600;
    color: #03854B;
  }

  &.title {
  }

  &.media {
    padding-right: 20px;
  }

  &.date {
    padding-right: 20px;
  }

  &.count {
    padding-right: 20px;
  }

  &.conf {
    width: 75px;
    text-align: right;
  }


  &.checker {
    width: 73px;
  }

`

const DashboardMenuTitle = styled(MenuTitle)`
  color: #444;
  margin-bottom: 10px;
`;


const FormArea = styled.div`
  margin: 20px 0;
  background: rgba(255, 255, 255, 1);
  border-radius: 15px;
  padding: 20px;
  width: calc(100% - 40px);
`;
export {
  Padding,
  FormField,
  PageTitle,
  FormBorderWrapper,
  Title,
  Gutter,
  Container,
  DashboardContainer,
  FlexSpaceWrapper,
  WhiteBtn,
  WhiteBtnA,
  MenuTitle,
  SearchLayout,
  TextInput,
  BlackBtnInput,
  FlexSpaceWrapperCenter,
  ButtonArea,
  RightMainColumn,
  WhiteBtnLink,
  ProjectArea,
  ProjectTitleArea,
  ProjectTitleLeft,
  ProjectTitle,
  ProjectDelivery,
  ProjectTitleRight,
  ProjectArticleArea,
  ArticleArea,
  ArticleHeader,
  ArticleList,
  ArticleListA,
  ArticleInfoTh,
  ArticleEventTitle,
  GreenBtn,
  ArticleInfo,
  DashboardMenuTitle,
  CenterFormContainer,
  FormArea,
  FlexWrapper,
  FaviconImg
};