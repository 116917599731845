import { DEFAULT_WELCOME_STATE } from '../constants/defaults';
import _ from 'lodash';

export default function welcomeReducer(state = DEFAULT_WELCOME_STATE, action){

  switch(action.type) {
    case "WINDOW_RESIZE":
      return {
        ...state,
        windowWidth: action.payload.windowWidth,
        windowHeight: action.payload.windowHeight
      };
    case "CHANGE_NOTICE_MESSAGE":
      return {
        ...state,
        noticeMessage: action.payload.noticeMessage
      }
    case 'UPDATE_AUTHENTICITY_TOKEN':
      return {
        ...state,
        form_authenticity_token: action.payload.form_authenticity_token
      }
    case 'UPDATE_SESSION_INFO':
      return {
        ...state,
        sessionSynced: true,
        enable_create_account: action.payload.enable_create_account,
        user_signed_in: action.payload.user_signed_in,
        current_user: action.payload.current_user,
        form_authenticity_token: action.payload.form_authenticity_token
      }
    case 'UPDATE_MEDIA_SOURCES':
      return {
        ...state,
        media_sources: action.payload.media_sources
      };
    case "CHANGE_USER":
      return {
        ...state,
        current_user: action.payload.current_user
      }
    case "LOG_IN": 
      return {
        ...state,
        current_user: action.payload.current_user,
        user_signed_in: true,
        form_authenticity_token: action.payload.form_authenticity_token
      };
    case "SIGN_OUT":
      return {
        ...state,
        current_user: null,
        user_signed_in: false
      }
    default: 
      return {
        ...state
      };
  }

};