import React, { Component } from 'react'
import styled from 'styled-components'
import media from '../stylesheets/media';
import _ from 'lodash';
import { FaviconImg } from '../stylesheets/components';

const HalfColumn = styled.div`
  
  margin: 0 0 50px 0;
`

const Title = styled.div`
  font-family: "Uniform Extra Condensed Bold";
  font-weight: 400;
  line-height: 1;
  font-size: 2.8em;
  text-transform: uppercase;
  color:#03854B;
  text-decoration: none;
`
const CollectionType = styled.div`
  color: #7E7E7E;
`

const MediaListTable = styled.table`
  
  margin-top: 10px;
  width: 100%;
  td, th {
    padding: 5px;
  }

  th {
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
  }

  td {
    border-bottom: 1px solid #cccccc;
  }

  .left {
    text-align: left;
  }
  .right {
    text-align: right;
  }
`

const PrevNextArea = styled.div`
  float:right;
  margin: 5px 0;
  button:nth-child(odd) {
    margin-right: 10px;
  }
`

class MediaList extends Component {
  async buttonClick(last_media_id){
    let { project_id } = this.props;

    const headers = {
      'X-CSRF-Token': this.props.form_authenticity_token,
      'X-Requested-With': 'XMLHttpRequest'
    }

    // this.props.dispatch(changeProgress(true));
    const response = await fetch(`/api/projects/${project_id}/media_lists/paginate.json?pg_col_id=${this.props.media_tag_info.tags_id}&last_media_id=${last_media_id}`, { method: "get", headers });
    if (response.ok) {
      let data = await response.json();
      
      if (data.media_lists.lists.length > 0) {
        this.props.updateMediaList(this.props.media_tag_info.tags_id, data.media_lists)
      } else {
        console.log("empty")
      }
    } else {
      let data = await response.json();

      // this.props.dispatch(changeProgress(false));
      return data;

    }

  }

  render() {
    let sortedLists = _.orderBy(this.props.lists, ['name'], ['asc']);

    return (
      <HalfColumn>
        <Title>
          { this.props.media_tag_info.label }
        </Title>
        <CollectionType>
          { _.startCase(_.toLower(this.props.type)) }<br/>
          { this.props.media_tag_info.description }
        </CollectionType>
        <PrevNextArea>
          { 
            this.props.first_media_id ? 
            <button onClick={this.buttonClick.bind(this, this.props.first_media_id)}>Prev</button> : null
          }
          { 
            this.props.last_media_id ? 
            <button onClick={this.buttonClick.bind(this, this.props.last_media_id)}>Next</button> : null
          }
        </PrevNextArea>
    
        <MediaListTable>
          <thead>
            <tr>
              <th className="left">
                Name
              </th>

              <th>
                # of Stories<br/>
                in 90 Days
              </th>

              <th>
                URL
              </th>
            </tr>
          </thead>
          <tbody>
            {
              _.map(sortedLists, list => {
                return (
                  <tr key={list.media_id}>
                    <td>
                    <FaviconImg src={`https://www.google.com/s2/favicons?domain=${list.url}`} alt={list.url}/> &nbsp;{ list.name }
                    </td>

                    <td className="right">
                      { list.num_stories_90.toFixed(0) }
                    </td>

                    <td>
                      <a href={list.url} target="_blank">{list.url}</a>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </MediaListTable>
      </HalfColumn>
    )
  }
}

export default MediaList;