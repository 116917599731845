import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { LeftColumn } from '../components';
import { DashboardContainer, RightMainColumn, MenuTitle, Title, WhiteBtnLink, Gutter, ProjectArea,
  ProjectTitleArea,
  ProjectTitleLeft,
  ProjectTitle,
  ProjectDelivery,
  ProjectTitleRight,
  ProjectArticleArea,
  ArticleArea,
  ArticleListA,
  ArticleHeader,
  ArticleList,
  ArticleInfo,
  DashboardMenuTitle } from '../stylesheets/components';
import styled from 'styled-components';
import moment from 'moment';
import df_logo from "../assets/df_logo.svg";
import ilda_logo from "../assets/ilda_logo.svg";
import fem_ur_logo from "../assets/fem_ur_logo.svg";

const SubTitleP = styled.div`
  font-family: "Uniform Extra Condensed Bold";
  font-weight: 400;
  line-height: 1;
  font-size: 3.2em;
  text-transform: uppercase;
  color:#03854B;
  text-decoration: none;
  max-width: 800px;
  margin-bottom: 0;
`

const Description = styled.div`
  margin-bottom: 20px;
  width: 500px;
  font-size: 1.2em;
  font-weight: 600;
  line-height: 1.4;
`

const CreditColumnWrap = styled.div`
  max-width: 800px;
  display: flex;
  justify-content: space-between;
`

const Column = styled.div`
  width: calc(50% - 40px);
`


const ColumnTitle = styled.div`
font-family: "Uniform Extra Condensed Bold";
  font-weight: 400;
  line-height: 1;
  font-size: 3.2em;
  color:#03854B;
  margin-bottom: 20px;
`

const MainTitleColor = styled.span`
  color: #37387e;
`;

const LogoArea = styled.div`
  display: flex;
  align-items: center;
  a {
    margin-right: 10px;
  }
`

const ColumnSubTitle = styled(DashboardMenuTitle)`

`
const ColumnPeopleArea = styled.div`
  margin-left: 20px;
  margin-bottom: 15px;
`

const Name = styled.span`
  font-weight: 600;
`
const Affi = styled.span`
  font-style: italic;
`
const ColumnPartTitle = styled.div``

class Dashboard extends Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }

  render() {
    let { user_signed_in, t } = this.props;
    let locale = this.props.match.params.locale;
    return (
      <Fragment>
        {
          user_signed_in ? 
          <DashboardContainer>
            <LeftColumn />
            <RightMainColumn>
              <SubTitleP>
                <MainTitleColor>{t("Data Against Feminicide")}:</MainTitleColor> {t("Email Alerts is a tool that helps feminicide activists and civil society organizations detect cases of feminicide from news reports.")}
              </SubTitleP>
              {
                locale == "en" ? 
                <Description>
                  It uses machine learning to scan news articles in a particular geography, identify probable cases of feminicide, and email a list of probable cases to activists. The tool is part of the larger <MainTitleColor><i>Data Against Feminicide</i></MainTitleColor> project organized by Catherine D'Ignazio (Data + Feminism Lab, MIT), Silvana Fumega (ILDA) and Helena Suárez Val (Feminicidio Uruguay).
                </Description> :
                <Description>
                  Usa aprendizaje automático para escanear artículos de noticias en una geografía en particular, identifica probables casos de feminicidios, y envía por correo electrónico una lista de casos probables a los activistas. La herramienta es parte del proyecto más amplio <MainTitleColor><i>Datos contra el feminicidio</i></MainTitleColor> impulasado por Catherine D'Ignazio (Data + Feminism Lab, MIT), Silvana Fumega (ILDA) y Helena Suárez Val (Feminicidio Uruguay).
                </Description>
              }
              <LogoArea>
                <a href="https://dataplusfeminism.mit.edu" target="_blank"><img src={df_logo} alt="df_logo" /></a>
                <a href="https://idatosabiertos.org/" target="_blank"><img src={ilda_logo} alt="ilda_logo" /></a>
                <a href="https://sites.google.com/view/feminicidiouruguay" target="_blank"><img src={fem_ur_logo} alt="Feminicide Uruguay Logo"/></a>
              </LogoArea>
              <Gutter h={40} />

              <CreditColumnWrap>
                <Column>
                  <ColumnTitle>
                    {t("Credits")}
                  </ColumnTitle>

                  <ColumnSubTitle>
                    {t("Project Leadership")}
                  </ColumnSubTitle>
                  <ColumnPeopleArea>
                    <Name>Catherine D'Ignazio</Name>&nbsp;&nbsp;<Affi>Data + Feminism Lab</Affi><br/>
                    <Name>Helena Suárez Val</Name>&nbsp;&nbsp;<Affi>Feminicidio Uruguay</Affi><br/>
                    <Name>Silvana Fumega</Name>&nbsp;&nbsp;<Affi>ILDA</Affi>
                  </ColumnPeopleArea>

                  

                  <ColumnSubTitle>
                    {t("Email Alert")}
                  </ColumnSubTitle>
                  <ColumnPartTitle>
                    Development
                  </ColumnPartTitle>
                  <ColumnPeopleArea>
                    <Name>Wonyoung So</Name> and <Name>Rahul Bhargava</Name>
                  </ColumnPeopleArea>
                  <ColumnPartTitle>
                    Design
                  </ColumnPartTitle>
                  <ColumnPeopleArea>
                    <Name>Wonyoung So</Name>
                  </ColumnPeopleArea>

                  <ColumnPartTitle>
                    Model Development
                  </ColumnPartTitle>
                  <ColumnPeopleArea>
                    <Name>Harini Suresh</Name>
                  </ColumnPeopleArea>
                  
                  <ColumnPartTitle>
                    Data Labeling (English)
                  </ColumnPartTitle>
                  <ColumnPeopleArea>
                    <Name>Mariel García-Montes</Name>, <Name>Ángeles Martínez</Name>, <Name>Catherine D'Ignazio</Name>, and <Name>Harini Suresh</Name>
                  </ColumnPeopleArea>

                  <ColumnPartTitle>
                    Data Labeling (Spanish)
                  </ColumnPartTitle>
                  <ColumnPeopleArea>
                    <Name>Helena Suárez Val</Name>, <Name>Ángeles Martínez</Name>, and <Name>Mariel García-Montes</Name>
                  </ColumnPeopleArea>
                  
                  <Gutter h={20} />

                  <ColumnSubTitle>
                    Data Highlighter
                  </ColumnSubTitle>
                  <ColumnPartTitle>
                    Development
                  </ColumnPartTitle>
                  <ColumnPeopleArea>
                    <Name>Harini Suresh</Name>, <Name>Rahul Bhargava</Name>, and <Name>Amelia Dogan</Name>
                  </ColumnPeopleArea>

                  <ColumnPartTitle>
                    Design
                  </ColumnPartTitle>
                  <ColumnPeopleArea>
                    <Name>Harini Suresh</Name>
                  </ColumnPeopleArea>

                  <Gutter h={20} />

                  <ColumnSubTitle>
                    {t("Project Management")} 
                  </ColumnSubTitle>
                  <ColumnPeopleArea>
                    <Name>Ángeles Martínez</Name>
                  </ColumnPeopleArea>
                  <Gutter h={20} />

                  <ColumnSubTitle>
                    {t("Qualitative Data Analysis")}
                  </ColumnSubTitle>
                  <ColumnPeopleArea>
                    <Name>Isadora Cruxen</Name> (lead), <Name>Ángeles Martínez</Name>, <Name>Mariel García-Montes</Name>, <Name>Catherine D'Ignazio</Name>, and <Name>Amelia Dogan</Name>
                  </ColumnPeopleArea>
                  
                </Column>

                <Column>
                  <ColumnTitle>
                    {t("Co-Design Partners")}
                  </ColumnTitle>
                  <ColumnPeopleArea style={{margin:0}}>
                    <Name>Dawn Wilcox</Name>&nbsp;&nbsp;<Affi>WomenCountUSA</Affi><br/>
                    <Name>Helena Suárez Val</Name>&nbsp;&nbsp;<Affi>Feminicidio Uruguay</Affi><br/>
                    <Name>Silvana Fumega</Name>&nbsp;&nbsp;<Affi>ILDA</Affi>
                  </ColumnPeopleArea>

                </Column>
              </CreditColumnWrap>
            </RightMainColumn>
          </DashboardContainer> : null
        }        
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    user_signed_in: state.user_signed_in,
    current_user: state.current_user
  }
}

export default withTranslation()(withRouter(connect(mapStateToProps)(Dashboard)));