import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Container, Title, FlexSpaceWrapper, FlexSpaceWrapperCenter, Gutter, WhiteBtn, MenuTitle, SearchLayout, TextInput, BlackBtnInput } from '../stylesheets/components';
import Routes from '../constants/routes';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { numberWithDelimiter, tablize } from '../utils';
import _ from 'lodash';

const Fragment = React.Fragment;


class FirstPage extends Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }
  componentDidMount(){
  }

  render() {
    let { windowWidth, user_signed_in, current_user } = this.props;
    return (
      <Fragment>
        <Container>
          <Gutter h={20} />
          <Title>
            Feminicides Email Alert
          </Title>

          {
            user_signed_in ? 
            <p>
              Welcome { current_user.email }!<br/><br/>
              Setup your account here to receive alerts:<br/>
              <Link to={Routes.user_path(current_user.id)}>Setup alert configuration</Link><br/><br/>
              -<br/>
              <Link to={Routes.edit_user_registration_path()}>Edit user information</Link><br/>
              <a href={Routes.destroy_user_session_path()} data-method="delete">Sign out</a>
            </p> :
            <p>
              <Link to={Routes.new_user_session_path()}>Sign in</Link><br/>
              <Link to={Routes.new_user_registration_path()}>Sign up</Link>
            </p>
          }

        </Container>
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    user_signed_in: state.user_signed_in,
    current_user: state.current_user
  }
}

export default withRouter(connect(mapStateToProps)(FirstPage));