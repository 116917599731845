import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { LeftColumn } from '../components';
import { DashboardContainer, RightMainColumn, MenuTitle, Title, WhiteBtnLink, Gutter, ProjectArea,
  ProjectTitleArea,
  ProjectTitleLeft,
  ProjectTitle,
  ProjectDelivery,
  ProjectTitleRight,
  ProjectArticleArea,
  ArticleArea,
  ArticleListA,
  ArticleHeader,
  ArticleList,
  FaviconImg,
  ArticleInfo,
  DashboardMenuTitle } from '../stylesheets/components';
import styled from 'styled-components';
import moment from 'moment';


class Dashboard extends Component {
  constructor(props){
    super(props);
    this.state = {
      articleEvents: [],
      articles: [],
      projects: [],
      activeEventId: null,
      activeEventArticles: []
    }
  }

  componentDidMount(){
    this.loadData();
  }

  
  async loadData(){

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    // this.props.dispatch(changeProgress(true));
    const response = await fetch("/api/users/dashboard.json", { method: "get", headers });
    if (response.ok) {
      let data = await response.json();
      this.setState({
        projects: data.projects,
        articleEvents: data.article_events,
        articles: data.articles
      })
    } else {
      // let data = await response.json();
      console.log(response);
    }
  }


  handleArticleClick(aeid, v){
    // if (v.length === 1) {
    //   window.open(_.first(v).url, "_blank");
    // } else {

      if (aeid === this.state.activeEventId) {
        this.setState({
          activeEventId: null,
          activeEventArticles: []
        })
      } else {
        this.loadActiveEventArticles(aeid);
      }
    // }

  }
  async loadActiveEventArticles(aeid){
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }


    const response = await fetch(`/api/article_events/${aeid}.json`, { method: "get", headers });
    if (response.ok) {
      let data = await response.json();
      this.setState({
        activeEventArticles: data.article_event.articles,
        activeEventId: aeid
      });
      
    } else {
      let data = await response.json();
      return data;
    }

  }
  render() {
    let { user_signed_in, t } = this.props;
    let { projects, articleEvents, articles } = this.state;
    let locale = this.props.match.params.locale;
    return (
      <Fragment>
        {
          user_signed_in ? 
          <DashboardContainer>
            <LeftColumn />
            <RightMainColumn>
              {
                _.map(projects, project => {

                  let selectedArticleEvents = _.filter(articleEvents, articleEvent => articleEvent.project_id === project.id)
                  return (
                    <ProjectArea key={project.id}>
                      <ProjectTitleArea>
                        <ProjectTitleLeft>
                          <ProjectTitle to={`/${locale}/projects/${project.id}`}>
                            { project.title }
                          </ProjectTitle>
                          <ProjectDelivery>

                            {t('Delivering')}&nbsp; 
                            <span className="frequency">
                              {
                                project.email_frequency === "Daily" ? 
                                t("once a day") :
                                t("once a week")
                              }
                            </span>
                          </ProjectDelivery>
                        </ProjectTitleLeft>
                        <ProjectTitleRight>
                          <WhiteBtnLink to={`/${locale}/projects/${project.id}/edit`}>
                            {t('Edit Setting')}
                          </WhiteBtnLink>
                        </ProjectTitleRight>
                      </ProjectTitleArea>

                      <ProjectArticleArea>
                        <DashboardMenuTitle>
                          {t('Recent News Articles')}
                        </DashboardMenuTitle>
                        {
                          selectedArticleEvents.length > 0 ? 
                            <ArticleArea>

                                           
                              <thead>
                                <ArticleHeader>
                                  <ArticleInfo className="head title">
                                    {t("Title")}
                                  </ArticleInfo>
                                  <ArticleInfo className="head media">
                                    {t("Count")}
                                  </ArticleInfo>
                                  <ArticleInfo className="head date">
                                    {t("Publish Date")}
                                  </ArticleInfo>
                                  {/* <ArticleInfo className="head conf">
                                    {t("Confidence")}
                                  </ArticleInfo> */}
                                </ArticleHeader>
                              </thead>     
                              <tbody>
                                {
                                  _.map(selectedArticleEvents, (article_event) => {
                                    
                                    let selectedArticles =  _.filter(articles, article => article.article_event_id === article_event.aeid)
                                    
                                    return (
                                      <>
                                        <ArticleList key={article_event.aeid} onClick={this.handleArticleClick.bind(this, article_event.aeid)} target="_blank">
                                          <ArticleInfo className="title">
                                            { article_event.aetitle }
                                          </ArticleInfo>
                                          <ArticleInfo className="title media">
                                            { article_event.count }
                                          </ArticleInfo>
                                          <ArticleInfo className="title date">
                                            { moment(article_event.latest_publish_date).format("MMM DD, YYYY") }
                                          </ArticleInfo>
                                          {/* <ArticleInfo className="title conf">
                                            { (article_event.confidence * 100).toFixed(1) }%
                                          </ArticleInfo> */}
                                        </ArticleList>
                                        
                                        {
                                          _.map(selectedArticles, article => {
                                            return (
                                              <ArticleList key={article.id} target="_blank">
                                                <ArticleInfo className="title">
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ㄴ &nbsp;&nbsp;
                                                  <FaviconImg src={`https://www.google.com/s2/favicons?domain=${article.media_url}`} alt={article.media_url}/> &nbsp;
                                                  <a href={article.url} target="_blank">
                                                    { article.media_name ? article.media_name : article.media_url }
                                                  </a>
                                                </ArticleInfo>
                                                <ArticleInfo className="title count">
                                                </ArticleInfo>
                                                <ArticleInfo className="title date">
                                                  { moment(article.publish_date).format("MMM DD, YYYY") }
                                                </ArticleInfo>
                                                <ArticleInfo className="title media">
                                                </ArticleInfo>
                                                {/* <ArticleInfo className="title conf">
                                                  { (article.confidence * 100).toFixed(1) }%
                                                </ArticleInfo> */}
                                              </ArticleList>
                                            )
                                          }) 
                                        }
                                      </>
                                    )
                                  })
                                }
                              </tbody>
                              <Gutter h={10} />
                              <Link to={`/${locale}/projects/${project.id}`}>
                                More...
                              </Link>
                            </ArticleArea> : 
                          <div>
                            {t('No recent news articles.')}
                          </div>
                        }
                        
                      </ProjectArticleArea>
                      <Gutter h={100} />
                    </ProjectArea>
                  );
                })
              }
            </RightMainColumn>
          </DashboardContainer> : null
        }        
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    user_signed_in: state.user_signed_in,
    current_user: state.current_user
  }
}

export default withTranslation()(withRouter(connect(mapStateToProps)(Dashboard)));