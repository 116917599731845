import React, { Component, Fragment, createContext } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom';
import { LeftColumn } from '../components';
import { changeNoticeMessage } from '../actions';
import { withTranslation } from 'react-i18next';
import { MediaList } from '../components';
import { DashboardContainer, RightMainColumn, MenuTitle, Title, WhiteBtnLink, Gutter, ProjectArea,
  ProjectTitleArea,
  ProjectTitleLeft,
  ProjectTitle,
  ProjectDelivery,
  ProjectTitleRight,
  FaviconImg,
  ProjectArticleArea,
  ArticleArea,
  ArticleListA,
  ArticleHeader,
  ArticleList,
  ArticleInfo,
  ArticleEventTitle,
  DashboardMenuTitle } from '../stylesheets/components';
import moment from 'moment';
import styled from 'styled-components';

const groupBy = (articleEvents) => {
  let pre_articlesEvents = _.values(_.groupBy(articleEvents, ae => ae.aeid));

  return pre_articlesEvents;
}

const DateFormArea = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
` 
const DateFormLabel = styled.div`
  margin-right: 10px;
`
const DateFormInput = styled.div`
  margin-right: 20px;
`

const DateFormFromToArea = styled.div`
  display: flex;
  align-items: center;
`
const DateFormSearchArea = styled.div`
  margin-right: 20px;
`

const SearchTitleInput = styled.input`
  width: 200px;
`

class MediaListShowPage extends Component {
  constructor(props){
    super(props);
    this.state = {
      media_lists: [],
      project: null
    }
  }

  componentDidMount(){
    this.loadData();
  }


  componentDidUpdate(prevProps){
    if (prevProps.match.params.id !== this.props.match.params.id && 
        prevProps.match.path !== "/:locale/projects/:id/media_lists") {
      this.loadData();
    }
  }

  async loadData(){
    let { t } = this.props;
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    const response = await fetch(`/api/projects/${this.props.match.params.id}/media_lists.json`, { method: "get", headers });
    if (response.ok) {
      let data = await response.json();
      this.setState({
        media_lists: data.media_lists,
        project: data.project
      });

    } else {
      let data = await response.json();
      this.props.dispatch(changeNoticeMessage(t("Cannot Find the Media List", {id: this.props.match.params.id})))
      this.props.history.push(`/${i18n.language}`);
      console.log(response);
    }
  }

  updateMediaList(tags_id, new_lists) {
    console.log(this.state);

    let new_media_lists = [...this.state.media_lists];
    let matchedIdx = _.findIndex(new_media_lists, list => { 
      return list.media_tag_info.tags_id == tags_id
    });

    new_media_lists[matchedIdx].lists = new_lists.lists
    new_media_lists[matchedIdx].last_media_id = new_lists.last_media_id
    
    this.setState({
      media_lists: new_media_lists
    })
  };


  render() {
    let { user_signed_in, t } = this.props;
    let { project, media_lists } = this.state;
    let locale = this.props.match.params.locale;
  
    return (
      <Fragment>
        {
          user_signed_in ? 
          <DashboardContainer>
            <LeftColumn />
            <RightMainColumn>
              {
                project ? 
                <ProjectArea>
                  <ProjectTitleArea>
                    <ProjectTitleLeft>
                      <ProjectTitle to={`/${locale}/projects/${project.id}`}>
                        { project.title }
                      </ProjectTitle>
                      
                    </ProjectTitleLeft>
                    <ProjectTitleRight>
                      <WhiteBtnLink to={`/${locale}/projects/${project.id}/edit`}>
                        {t('Edit Setting')}
                      </WhiteBtnLink>
                    </ProjectTitleRight>
                  </ProjectTitleArea>
                </ProjectArea> : null
              }

              {
                media_lists.length > 0 ?
                  <div>
                    {
                      _.map(media_lists, media_list => {
                        return (
                          <MediaList key={media_list.media_tag_info.label} updateMediaList={this.updateMediaList.bind(this)} project_id={project.id} {...media_list} />
                        )
                      })
                    }
                  </div>
                : 
                <>
                </>
              }
            </RightMainColumn>
          </DashboardContainer> : null
        }        
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    user_signed_in: state.user_signed_in,
    current_user: state.current_user
  }
}

export default withTranslation()(withRouter(connect(mapStateToProps)(MediaListShowPage)));