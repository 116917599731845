import React, { Component } from 'react'
import styled from 'styled-components';
import { connect } from 'react-redux';
import { changeNoticeMessage } from '../actions';
import { FlexWrapper, FlexSpaceWrapper, MenuTitle, Gutter, FormField, Desc } from '../stylesheets/components';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

const SettingArea = styled.div`

`;

const EditMenuTitle = styled(MenuTitle)`
  margin-bottom: 5px;
`;

const WidthWrapper = styled.div`
  width: 400px;
`


const InputWhite = styled.input`
  width: 200px;
  margin-right: 10px;
`;

class BasicInfoSetting extends Component {
  constructor(props){
    super(props);
  }



  async handleUpdate(label, key, e) {
    let { project } = this.props;

    const headers = {
      'X-CSRF-Token': this.props.form_authenticity_token,
      'X-Requested-With': 'XMLHttpRequest'
    }

    let formData = new FormData();
    if (e.target) {
      formData.append(key, e.target.value);
    } else if (e.value) {
      formData.append(key, e.value);
    }
    
    // this.props.dispatch(changeProgress(true));
    const response = await fetch(`/api/projects/${project.id}.json`, { method: "put", body: formData, headers });
    if (response.ok) {
      let data = await response.json();

      this.props.dispatch(changeNoticeMessage(`Successfully changed ${label}.`));
      this.props.updateProject(data.project);

    } else {
      let data = await response.json();

      // this.props.dispatch(changeProgress(false));
      return data;

    }


  }

  handleTitleChange(e){
    this.props.updateProject({
      ...this.props.project,
      title: e.target.value  
    });

  }

  handleOrgNameChange(e){
    this.props.updateProject({
      ...this.props.project,
      organization_name: e.target.value  
    });
  }



  handleNewscatcherCountryChanged(e){
    this.props.updateProject({
      ...this.props.project,
      newscatcher_country: e.target.value  
    });
  }
  
  render() {
    let { project, t } = this.props;
    return (
      <SettingArea>
        <EditMenuTitle>
          {t("Basic Information")}
        </EditMenuTitle>
        <WidthWrapper>
          <FormField>
            <label>{t("Project Title")}</label>
            <InputWhite type="text" value={project.title} onChange={this.handleTitleChange.bind(this)} onBlur={this.handleUpdate.bind(this, "title", "project[title]")} name="project[title]"/>
          </FormField>    
          <FormField>
            <label>{t("Organization Name")}</label>
            <InputWhite type="text" value={project.organization_name} onChange={this.handleOrgNameChange.bind(this)} onBlur={this.handleUpdate.bind(this, "organization name", "project[organization_name]")} name="project[organization_name]"/>
          </FormField>    
          {/* <FormField>
            <label>{t("Newscatcher Country")}</label>
            <InputWhite type="text" value={project.newscatcher_country} placeholder="e.g., BR, US (2 characters)" onChange={this.handleNewscatcherCountryChanged.bind(this)} onBlur={this.handleUpdate.bind(this, "newscatcher country", "project[newscatcher_country]")} name="project[newscatcher_country]"/>
            <i>
              * Required if you want to use Newscatcher. If you don't want, please leave it blank.
            </i>
          </FormField>     */}
        </WidthWrapper>

      </SettingArea>
    )
  }
}

let mapStateToProps = state => {
  return {
    user_signed_in: state.user_signed_in,
    current_user: state.current_user,
    form_authenticity_token: state.form_authenticity_token
  }
}
export default withTranslation()(connect(mapStateToProps)(BasicInfoSetting));