import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Container, FlexSpaceWrapper, Gutter, CenterFormContainer, FormArea, MenuTitle, PageTitle, FormField, FormBorderWrapper, ButtonArea, BlackBtnInput, WhiteBtnA } from '../stylesheets/components';
import Routes from '../constants/routes';
import { Link } from 'react-router-dom';
import { changeNoticeMessage, logIn } from '../actions';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { Form, Field } from 'react-final-form';
import {LanguageChanger } from '../components';
import { withTranslation } from 'react-i18next';
import { importImageByLocale } from '../utils';

const Fragment = React.Fragment;

const LabLogo = styled.a`
  position: absolute;
  right: 0;
  top: 40px;
`;


const SigninFlexWrapper = styled.div`
  width: 100%;
`;


class Signin extends Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }
  componentDidMount(){
    // this.loadData();
  }


  async handleSubmit(values) {
    let locale = this.props.match.params.locale;
    let { t } = this.props;
    const headers = {
      'X-CSRF-Token': this.props.form_authenticity_token,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
    // this.props.dispatch(changeProgress(true));
    const response = await fetch(Routes.api_user_session_path(), { method: "post", body: JSON.stringify(values), headers });
    if (response.ok) {
      let data = await response.json();
      console.log(data);
      this.props.dispatch(logIn(data.user, data.csrf_token));
      this.props.dispatch(changeNoticeMessage(t("Successfully Signed In.")));
      this.props.history.push(`/${locale}`)
      // this.props.dispatch(changeProgress(false));

    } else {
      let data = await response.json();

      // this.props.dispatch(changeProgress(false));
      return data;

    }


  }

  handleValidate(values) {
    const errors = {};
    let { t } = this.props;
    if (values.user) {


      if (!values.user.email) {
        if (!errors.user) {
          errors.user = {};
        }
        errors.user.email = t('Required');
      }

      if (!values.user.password) {
        if (!errors.user) {
          errors.user = {};
        }
        errors.user.password = t('Required');
      }



    }
    return errors;
  }




  render() {
    let { windowWidth, form_authenticity_token, t, enable_create_account } = this.props;
    let locale = this.props.match.params.locale;
    console.log("enable_create_)Account", enable_create_account);
    return (
      <Fragment>
        <CenterFormContainer>
          <SigninFlexWrapper>
            <PageTitle>
              <img src={importImageByLocale(locale)} alt="Data Against Feminicide: Email Alert" />
            </PageTitle>

            <FormArea>
              <MenuTitle>
                { t('Sign In') }
              </MenuTitle>
              <Form
                onSubmit={this.handleSubmit.bind(this)}
                validate={this.handleValidate.bind(this)}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                  <form method="post" onSubmit={handleSubmit}>
                    <FormBorderWrapper>
                      <FormField>
                        <label>{ t('Email') }</label>
                        <Field
                          name="user[email]"
                          autoFocus={true}
                          autoComplete="username"
                          placeholder="e.g., person@mit.edu"
                        >
                          {({ input, meta }) => (
                            <div className="right">
                              <input {...input} type="email" autoComplete="email" placeholder="e.g., person@mit.edu" />
                              {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || t(meta.submitError)}</span>}
                            </div>
                          )}
                        </Field>
                      </FormField>
                      <FormField>
                        <label>{ t('Password') }</label>
                        <Field
                          name="user[password]"
                        >
                          {({ input, meta }) => (
                            <div className="right">
                              <input {...input} type="password" autoComplete="password" placeholder="*******" />
                              {(meta.error || meta.submitError) && meta.touched && <span className="error">{meta.error || t(meta.submitError)}</span>}
                            </div>
                          )}
                        </Field>
                      </FormField>
                      
                    </FormBorderWrapper>
                    
                    <ButtonArea>
                      <div>
                        <BlackBtnInput style={{ marginRight: 10}} type="submit" value={t('Sign In')} disabled={submitting || pristine} />
                        {
                          enable_create_account ? 
                          <WhiteBtnA href={`/${locale}/users/sign_up`}>
                            { t('Create Account') } 
                          </WhiteBtnA>: null
                        }
                        
                      </div>
                      <div style={{textAlign: "right"}}>
                        <a href={`/${locale}/users/password/new`}>{t('Forgot password?')}</a>
                      </div>
                    </ButtonArea>
                  </form>
                )}
              />  
            </FormArea>
            <LanguageChanger />
            <Gutter h={5} />
            <p>
              {t("Contact us")}: <a href="mailto:dataplusfeminism@mit.edu">dataplusfeminism@mit.edu</a>
            </p>
          </SigninFlexWrapper>
          
        </CenterFormContainer> 
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    enable_create_account: state.enable_create_account,
    windowWidth: state.windowWidth,
    form_authenticity_token: state.form_authenticity_token
  }
}

export default withTranslation()(withRouter(connect(mapStateToProps)(Signin)));