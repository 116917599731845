import React, { Component } from 'react'
import { 
  FaviconImg,
  ArticleListA,
  ArticleList,
  ArticleInfo } from '../stylesheets/components';
import moment from 'moment';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FeminicideCheckDropdown } from './';


const TitleButton = styled.button`
  text-decoration: underline;
  text-align:left;

  &:hover {
    opacity: 0.6;
  }
`

class ArticleListComp extends Component {
  constructor(props){
    super(props);
  }

  determineCheck(){
    let { checkedArticles, article_event } = this.props;
    return !_.isUndefined(checkedArticles[article_event.aeid]);
  }

  render() {
    let { t, article_event, opt_in_improving_model, project_id, archived } = this.props;
    return (
      <>
        <ArticleList key={article_event.aeid}  target="_blank">

        <ArticleInfo className="checkbox">

          <input type="button" value={archived ? t("Unarchive") : t("Archive")} onClick={this.props.handleArchive.bind(this, article_event.aeid)} />
          </ArticleInfo>

          <ArticleInfo className="checkbox">
            <input type="checkbox" name="checkbox_title" checked={this.determineCheck()} onChange={this.props.handleIndividualCheckBox.bind(this, article_event.aeid)} />
          </ArticleInfo>
          <ArticleInfo className="title">
            <TitleButton onClick={this.props.handleArticleClick.bind(this, article_event.aeid)}>
              { article_event.aetitle }
            </TitleButton>
          </ArticleInfo>
          <ArticleInfo className="title date">
            { moment(article_event.latest_publish_date).format("MMM DD, YYYY") }
          </ArticleInfo>
          <ArticleInfo className="title date">
            { moment(article_event.created_at).format("MMM DD, YYYY") }
          </ArticleInfo>
          <ArticleInfo className="title count">
            { article_event.count }
          </ArticleInfo>
          {
            opt_in_improving_model ? 
            <ArticleInfo className="title checker">
              <FeminicideCheckDropdown is_relevant={article_event.is_relevant} project_id={project_id} title={article_event.aetitle} id={article_event.aeid} />
            </ArticleInfo> :
            null
          }
          {/* <ArticleInfo className="title conf">
            { (article_event.confidence * 100).toFixed(1) }%
          </ArticleInfo> */}
        </ArticleList>

        {
          _.map(this.props.articles, article => {
            return (
              <ArticleList key={article.id} target="_blank">
                <ArticleInfo className="checkbox">
                </ArticleInfo>
                <ArticleInfo className="checkbox">
                </ArticleInfo>
                <ArticleInfo className="title">
                  &nbsp;&nbsp;ㄴ &nbsp;&nbsp;
                  <FaviconImg src={`https://www.google.com/s2/favicons?domain=${article.media_url}`} alt={article.media_url}/> <a href={article.url} target="_blank">{ article.media_name ? article.media_name : article.media_url }</a>
                </ArticleInfo>
                <ArticleInfo className="title date">
                  { moment(article.publish_date).format("MMM DD, YYYY") }
                </ArticleInfo>
                <ArticleInfo className="title date">
                </ArticleInfo>
                <ArticleInfo className="title count">
                </ArticleInfo>
                {
                  opt_in_improving_model ? 
                  <ArticleInfo className="title checker">
                  </ArticleInfo> : null
                }
                {/* <ArticleInfo className="title conf">
                  { (article.confidence * 100).toFixed(1) }%
                </ArticleInfo> */}
              </ArticleList>
            )
          })
        }
      </>
    )
  }
}


export default withTranslation()(ArticleListComp);