import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { LeftColumn, SearchTermsTester, Sharing, CollectionConfirm, TimezoneSetting, BasicInfoSetting, OptinConfirm } from '../components';
import { changeNoticeMessage } from '../actions';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ButtonArea, BlackBtnInput, DashboardContainer, RightMainColumn, MenuTitle, Title, WhiteBtnLink, Gutter, ProjectArea, FormField,
  ProjectTitleArea,
  ProjectTitleLeft,
  ProjectTitle,
  ProjectDelivery,
  ProjectTitleRight,
  ProjectArticleArea,
  ArticleArea,
  ArticleHeader,
  ArticleList,
  ArticleInfo,
  DashboardMenuTitle } from '../stylesheets/components';

const SettingArea = styled.div`

`;


const EditMenuTitle = styled(MenuTitle)`
  margin-bottom: 5px;
`;

class ProjectEdit extends Component {
  constructor(props){
    super(props);
    this.state = {
      project: null,
      timezoneOptions: [],
      languageModels: []
    }
  }

  componentDidMount(){
    this.loadData();
  }

  async loadData(){
    let { t, i18n } = this.props;
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }


    const response = await fetch(`/api/projects/${this.props.match.params.id}/edit.json`, { method: "get", headers });
    if (response.ok) {
      let data = await response.json();
      this.setState({
        project: data.project,
        timezoneOptions: data.timezones,
        languageModels: data.language_models
      })
    } else {
      let data = await response.json();
      this.props.dispatch(changeNoticeMessage(t("Cannot Find Project", {id: this.props.match.params.id})))
      this.props.history.push(`/${i18n.language}`);
      console.log(response);
    }
  }

  updateProject(project) {
    this.setState({
      project
    });
  }

  render() {
    let { user_signed_in, t } = this.props;
    let { project, timezoneOptions, languageModels } = this.state;
    let locale = this.props.match.params.locale;
    return (
      <Fragment>
        {
          user_signed_in ? 
          <DashboardContainer>
            <LeftColumn />
            <RightMainColumn>
              {
                project ? 
                <ProjectArea>
                  <ProjectTitleArea>
                    <ProjectTitleLeft>
                      <ProjectTitle to={`/${locale}/projects/${project.id}`}>
                        { project.title }
                      </ProjectTitle>
                      <ProjectDelivery>
                        {
                          project.organization_name ?
                          <>
                            By {project.organization_name} —
                          </> : null
                         } {t('Delivering')}&nbsp; 
                        <span className="frequency">
                          {
                            project.email_frequency === "Daily" ? 
                            t("once a day") :
                            t("once a week")
                          }
                        </span>
                      </ProjectDelivery>
                    </ProjectTitleLeft>
                  </ProjectTitleArea>

                  <BasicInfoSetting project={project} updateProject={this.updateProject.bind(this)} />
                  <Gutter h={50} />
                  <TimezoneSetting project={project} languageModels={languageModels} timezoneOptions={timezoneOptions} updateProject={this.updateProject.bind(this)} />
                  <Gutter h={50} />
                  <Sharing project={project} updateProject={this.updateProject.bind(this)} />
                  <Gutter h={50} />
                  <CollectionConfirm project={project} updateProject={this.updateProject.bind(this)}  />
                  <Gutter h={50} />
                  <OptinConfirm project={project} updateProject={this.updateProject.bind(this)}  />
                  <Gutter h={50} />
                  <SettingArea>
                    <EditMenuTitle>
                      {t("Media Sources")}
                    </EditMenuTitle>
                    <Link to={`/${locale}/projects/${project.id}/media_lists`}>{t("Show Media Sources")}</Link><br/>
                    {t("Email us to add new media sources to scan. (dataplusfeminism@mit.edu)")}
                  </SettingArea>
                  <Gutter h={50} />
                  
                  <SearchTermsTester project={project} updateProject={this.updateProject.bind(this)} />
                </ProjectArea> : null
              }
              
            </RightMainColumn>
          </DashboardContainer> : null
        }        
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    user_signed_in: state.user_signed_in,
    current_user: state.current_user,
    form_authenticity_token: state.form_authenticity_token
  }
}

export default withTranslation()(withRouter(connect(mapStateToProps)(ProjectEdit)));