import React, { Component } from 'react'
import welcomeReducer from './reducers/welcomeReducer';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import Welcome from './pages/Welcome';
import { BrowserRouter as Router } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from '../components/constants/i18n';
import "./stylesheets/reset.scss";
import "./stylesheets/fonts.scss";
import "./stylesheets/application.scss";

import { Route, Switch, Redirect } from "react-router-dom";

export default class App extends Component {
  componentDidMount() {

  }
  render() {

    return (
      <I18nextProvider i18n={i18n}>
        <Provider store={createStore(welcomeReducer)}>
          <Router>
            <Welcome />
          </Router>
        </Provider>
      </I18nextProvider>
    )
  }
}