import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Container, FlexSpaceWrapper, Gutter, CenterFormContainer, FormArea, MenuTitle, PageTitle, FormField, FormBorderWrapper, ButtonArea, BlackBtnInput, WhiteBtnA } from '../stylesheets/components';
import Routes from '../constants/routes';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { SearchPersonMapContainer } from '../components';
import { numberWithDelimiter, tablize } from '../utils';
import _ from 'lodash';
import { changeNoticeMessage } from '../actions';
import { Form, Field } from 'react-final-form';
import {LanguageChanger } from '../components';
import { withTranslation } from 'react-i18next';
import logo_en from "../assets/logo_en.svg";
import logo_es from "../assets/logo_es.svg";


const Fragment = React.Fragment;
const Hero = styled.div`
  background-color: white;
`;

const FlexSpaceBaseline = styled(FlexSpaceWrapper)`
  align-items: flex-end;
`;

const LabLogo = styled.a`
  position: absolute;
  right: 0;
  top: 40px;
`;

const HeroContainer = styled(Container)`
  padding: 40px 0 50px 0;
  position: relative;
`;  

const Column = styled.div`
  p {
    font-family: "Playfair Display";
    font-size: 1.2em;
    line-height: 1.4;
    color: white;
  }

  width: calc(50% - 20px);
`;
const Disclaimer = styled.div`
  font-family: "Brandon Text";
  font-size: 0.9em;
  font-style: italic;
  color: #888;
  line-height: 1.2;
`;

const SigninFlexWrapper = styled.div`
  width: 100%;
`;

class PasswordNew extends Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }
  componentDidMount(){
    // this.loadData();
  }


  
  async handleSubmit(values) {
    let locale = this.props.match.params.locale;
    const headers = {
      'X-CSRF-Token': this.props.form_authenticity_token,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    values = {
      ...values,
      user: {
        ...values.user,
        data: {
          locale: locale
        }
      }
    };

    const response = await fetch(Routes.api_user_password_path(), { method: "post", body: JSON.stringify(values), headers });
    if (response.ok) {
      let data = await response.json();
      console.log(data);
      this.props.dispatch(changeNoticeMessage(`Just sent an email to ${data.email}. Please check your inbox!`))
      this.props.history.push(`/${locale}/users/sign_in`);
      // this.props.dispatch(changeProgress(false));

    } else {
      let data = await response.json();
      // this.props.dispatch(changeProgress(false));

      return data;

    }
  }

  handleValidate(values) {
    const errors = {};
    let { t } = this.props;
    if (values.user) {


      if (!values.user.email) {
        if (!errors.user) {
          errors.user = {};
        }
        errors.user.email = t('Required');
      }



    }
    return errors;
  }



  render() {
    let { windowWidth, form_authenticity_token, t } = this.props;
    let locale = this.props.match.params.locale;
    return (
      <Fragment>
        <CenterFormContainer>
          <SigninFlexWrapper>
            <PageTitle>
              <img src={locale === "en" ? logo_en : logo_es } alt="Data Against Feminicide: Email Alert" />
            </PageTitle>

            <FormArea>
              <MenuTitle>
                {t('Request a new password')}
              </MenuTitle>
                <Form
                  onSubmit={this.handleSubmit.bind(this)}
                  validate={this.handleValidate.bind(this)}
                  render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form method="post" onSubmit={handleSubmit}>
                    <input type="hidden" name="authenticity_token" value={form_authenticity_token} />
                      <FormBorderWrapper>
                        <FormField>
                          <label>{t('Email')}</label>
                          <Field
                            name="user[email]"
                            component="input"
                            type="email"
                            autoFocus={true}
                            autoComplete="username"
                            placeholder="e.g., person@gmail.com"
                          />
                        </FormField>        
                      </FormBorderWrapper>
                      
                      <ButtonArea>
                        <div>
                          <BlackBtnInput style={{ marginRight: 10}} type="submit" value={t('Submit')} disabled={submitting || pristine} />
                        </div>
                      </ButtonArea>
                    </form>
                  )}
                />      
              </FormArea>
              <LanguageChanger />
          
          </SigninFlexWrapper>
        </CenterFormContainer> 
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    form_authenticity_token: state.form_authenticity_token
  }
}

export default withTranslation()(withRouter(connect(mapStateToProps)(PasswordNew)));