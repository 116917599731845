import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Container, FlexSpaceWrapper, FlexSpaceWrapperCenter, Gutter, WhiteBtn, MenuTitle, SearchLayout, TextInput, BlackBtnInput } from '../stylesheets/components';
import Routes from '../constants/routes';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import _ from 'lodash';
import moment from 'moment';
import { updateMediaSources } from '../actions';
import Select from 'react-select'
import countryOptions from "../constants/country_options";

const Fragment = React.Fragment;
const Title = styled.h1`
  font-size:1.5em;
  margin-bottom: 20px;
  font-weight: 700;
`;

const Subtitle = styled.h2`
  font-size:1.2em;
  font-weight: 600;
  margin-bottom: 10px;
`;


class SetupConfig extends Component {
  constructor(props){
    super(props);
  }
  componentDidMount(){
    this.loadData();
  }

  async loadData(){

    // const headers = {
    //   'Content-Type': 'application/json',
    //   'Accept': 'application/json',
    //   'X-Requested-With': 'XMLHttpRequest'
    // }

    // // this.props.dispatch(changeProgress(true));
    // const response = await fetch("https://sources.mediacloud.org/static/data/country-collections.json", { method: "get", headers });
    // if (response.ok) {
    //   let data = await response.json();
    //   console.log(data);
    // } else {
    //   // let data = await response.json();
    //   console.log(response);
    // }

  }

  handleChange(options){
    if (!_.isNull(options)) {
      console.log(options);

      let values = _.map(options, opt => {
        return opt.value;
      });


      this.props.dispatch(updateMediaSources(values.join(",")));
    } else {


      this.props.dispatch(updateMediaSources(""));
    }
  }

  setValues(mediaSources){
    if (!_.isNull(mediaSources)) {
      let values = _.map(mediaSources.split(","), ms => {
        var opt = _.find(countryOptions, opt => {
          return opt.value === Number(ms);
        });
        return opt;
      });
      return values;
    } else {
      return [];
    }
  }

  render() {
    let { windowWidth, user_signed_in, current_user, form_authenticity_token, media_sources } = this.props;
    if (current_user) {
      console.log(current_user.confirmed_collection);
    }
    
    return (
      <Fragment>
        {
          user_signed_in ? 
          <Container>
            <Gutter h={20} />
            <Link to="/">Back</Link><br/><br/>
            <Title>
              Feminicides Email Alert Setting
            </Title>
            <Subtitle>
              Update the alert setting
            </Subtitle>
            <form action={Routes.user_update_alert_setting_path(current_user.id)} acceptCharset="UTF-8" method="post">
              <input type="hidden" name="_method" value="put" /><input type="hidden" name="authenticity_token" value={form_authenticity_token} />
                          
              <div className="field">
                <label htmlFor="user_search_term">Search term</label><br />
                <input type="text" defaultValue={current_user.search_term} name="user[search_term]" id="user_search_term" style={{ width: 400}} />
              </div>


              <div className="field">
                <label htmlFor="user_publish_day_from">Publish day from</label><br />
                <input defaultValue={moment(current_user.publish_day_from).format("YYYY-MM-DD")} type="date" name="user[publish_day_from]" id="user_publish_day_from" />
              </div>


              <div className="field">
                <label htmlFor="user_publish_day_to">Publish day to</label><br />
                <input defaultValue={moment(current_user.publish_day_to).format("YYYY-MM-DD")} type="date" name="user[publish_day_to]" id="user_publish_day_to" />
              </div>

              <Gutter h={30} />
              <h3>
                Add Media Sources
              </h3>

              <Select options={countryOptions} value={this.setValues(media_sources)} isMulti={true} onChange={this.handleChange.bind(this)} />
              <input value={media_sources} type="hidden" name="user[media_sources]" id="user_media_sources" />


              <Gutter h={30} />
              <div className="actions">
                <input type="submit" name="commit" value="Update" data-disable-with="Update" />
              </div>
            </form>

            <Gutter h={50} />

            <Subtitle>
              Start Collection of Articles
            </Subtitle>

            {
              !current_user.confirmed_collection ? 
              <div>
                {
                  current_user.confirmed_at ? 
                  <div>
                    You cancelled collecting articles at { moment(current_user.confirmed_at).format("YYYY-MM-DD  HH:mm") } <br/>
                    <a href={Routes.user_update_start_collection_path(current_user.id)} data-method="put">Start Collecting Articles</a>
                  </div> : null
                }
              </div> :
              <div>
                You started collecting articles at { moment(current_user.confirmed_at).format("YYYY-MM-DD  HH:mm") } <br/>
                <a href={Routes.user_cancel_collection_path(current_user.id)} data-method="put">Cancel Collecting Articles</a>
              </div>
            }
          </Container> : null
        }
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    user_signed_in: state.user_signed_in,
    current_user: state.current_user,
    form_authenticity_token: state.form_authenticity_token,
    media_sources: state.media_sources
  }
}

export default withRouter(connect(mapStateToProps)(SetupConfig));

